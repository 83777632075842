export function isHighScore(score: number) {
  return score < 50;
}

export function isModerateScore(score: number) {
  return score < 80 && score >= 50;
}

export function isLowRisk(score: number) {
  return score >= 80;
}

export function isLowRating(rating: number) {
  return rating === 5;
}

export function isModerateRating(rating: number) {
  return rating === 4;
}
