export const useBrowserLanguages = () => {
  const languagesToAcceptHeaders = (languages: string[], startingQuality = 1) => {
    return languages.map((language, index) => `${language};q=${startingQuality - (index * 0.1)}`);
  }

  const appendToAcceptHeaderString = (language: string, startingQuality = 1) => {
    const browserLanguages = navigator.languages || [navigator.language];
    const languages = [language.replace('_', '-'), ...browserLanguages];

    return languagesToAcceptHeaders(languages, startingQuality).join(',');
  }

  return { languagesToAcceptHeaders, appendToAcceptHeaderString };
}
