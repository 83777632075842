<script setup lang="ts">
import {
  AlertDomainTypes,
  AlertFrequency,
  type AlertsResource,
  AlertType,
  type CustomerResource,
} from '@/types/types.gen';
import { type AlertConfig, useAlertConfig } from '@/Pages/Alerts/config';
import { type InertiaForm, Link } from '@inertiajs/vue3';

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Card from 'primevue/card';

import { computed, inject, reactive } from 'vue';
import ActivityForm from '@/Pages/Alerts/Partials/ActivityForm.vue';
import EmailForm from '@/Pages/Alerts/Partials/EmailForm.vue';
import Button from 'primevue/button';
import VerificationOptionsPanel from '@/Pages/Alerts/Partials/Options/VerificationOptionsPanel.vue';
import FrequencyForm from '@/Pages/Alerts/Partials/FrequencyForm.vue';
import DeleteConfirmButton from '@/Components/DeleteConfirmButton.vue';
import { useRoute } from 'ziggy-js';
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import { useFormatters } from '@/Utils/Formatting';

const { customer, alert } = defineProps<{
  customer: CustomerResource;
  alert?: AlertsResource;
}>();

const emits = defineEmits<{
  (e: 'submit'): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;

const formatter = useFormatters();

const state = reactive({
  activePanel: alert ? 'email' : 'activity',
  requiredStepsComplete: alert !== undefined,
  activityDisabled: alert !== undefined,
  frequencyDisabled: true,
  emailDisabled: alert === undefined,
  showConditions: false,
  showFrequency: false,
});

const alertTypeConfig = computed((): AlertConfig => {
  return useAlertConfig().filter((alert: AlertConfig) => alert.type === form.type)[0];
});

const showVerificationConditions = computed(() => {
  return state.showConditions
    && (
      form.type === AlertType.DNS_DELEGATION_VERIFIED
      || form.type === AlertType.DNS_DELEGATION_UNVERIFIED
      || form.type === AlertType.DOMAIN_SETTINGS_CHANGE
    )
});

const canSetFrequency = computed(() => {
  return form.type === AlertType.DMARC_REJECT_CERTIFICATE || form.type === AlertType.CUSTOMER_EXPOSURE_REPORT
});

const completeStep = (step: string) => {
  if (step === 'back-to-activity') {
    state.showConditions = false;
    return;
  }
  switch (step) {
    case 'activity':
    case 'conditions':
    case 'frequency':
      if (step === 'activity') {
        setAlertTypeDefaults();
      }

      if (alertTypeConfig.value.hasConditions && step === 'activity') {
        state.showConditions = true;
        break;
      }

      if (canSetFrequency.value && step === 'activity') {
        state.activePanel = 'frequency';
        state.frequencyDisabled = false;
        state.requiredStepsComplete = false;
      } else {
        state.activePanel = 'email';
        state.emailDisabled = false;
        state.requiredStepsComplete = true;
      }
      break;
  }
};

const setAlertTypeDefaults = () => {
  switch (form.type) {
    case AlertType.DNS_DELEGATION_VERIFIED:
    case AlertType.DNS_DELEGATION_UNVERIFIED:
    case AlertType.DOMAIN_SETTINGS_CHANGE:
      form.conditions = {
        statistics: null,
        domain_events: {
          types: [AlertDomainTypes.DMARC],
        },
      };
      break;
    case AlertType.DMARC_REJECT_CERTIFICATE:
    case AlertType.CUSTOMER_EXPOSURE_REPORT:
      form.frequency = AlertFrequency.MONTHLY;
      break;
    case AlertType.DOMAIN_COMPLIANCE_DROP:
      form.conditions = {
        statistics: {
          volume: 75,
          isPercentage: true,
        },
        domain_events: {
          types: [AlertDomainTypes.DMARC],
        },
      };
  }
}

const deleteAlert = () => {
  if (alert?.uuid) {
    const route = useRoute();
    form.delete(route('ui.alerts.destroy', { customer: customer.uuid, alert: alert.uuid }));
  }
}

if (alert?.uuid) {
  state.requiredStepsComplete = true;
  state.emailDisabled = false;

  if (canSetFrequency.value) {
    state.frequencyDisabled = false;
  }

  if (alertTypeConfig.value.hasConditions) {
    state.showConditions = true;
    state.activityDisabled = false;
  }
}
</script>

<template>

    <Card>
      <template #content>
        <div class="mb-4" v-if="alert?.uuid">
          <FormGroup :label="$t('alerts.fields.status.input_label')">
            <template v-slot:element="slotProps">
              <SelectButton
                v-model="form.is_enabled"
                v-bind="slotProps"
                :allow-empty="false"
                :options="[
                  { label: $t('global.fields.on_off.options.off'), value: false },
                  { label: $t('global.fields.on_off.options.on'), value: true }
                ]"
                optionLabel="label"
                optionValue="value"
              />
            </template>
          </FormGroup>
        </div>
        <Accordion v-model:value="state.activePanel">
          <AccordionPanel value="activity" :disabled="state.activityDisabled">
            <AccordionHeader>
              <div class="flex items-center gap-2 w-full">
                <i class="pi pi-sliders-h"></i>
                <div class="flex justify-between w-full mr-3">
                  <div>{{ $t('alerts.activity.heading') }}</div>
                  <div class="text-muted-color font-normal">{{ $t(`alerts.activity.options.${form.type}.heading`) }}</div>
                </div>
              </div>
            </AccordionHeader>
            <AccordionContent>
              <ActivityForm v-if="!state.showConditions" @complete-step="completeStep" />
              <VerificationOptionsPanel v-if="showVerificationConditions" @complete-step="completeStep" />

            </AccordionContent>
          </AccordionPanel>

          <AccordionPanel value="frequency" :disabled="state.frequencyDisabled">
            <AccordionHeader>
              <div class="flex items-center gap-2 w-full">
                <i class="pi pi-calendar"></i>
                <div class="flex justify-between w-full mr-3">
                  <div>{{ $t('alerts.frequency.heading') }}</div>
                  <div v-if="state.frequencyDisabled" class="text-muted-color font-normal">{{ $t('alerts.frequency.disabled-text') }}</div>
                  <div v-if="!state.frequencyDisabled && form.frequency && form.start_at" class="text-muted-color font-normal capitalize">
                    {{ $t(`alerts.properties.frequency.options.${form.frequency}`) }} / {{ $t('alerts.frequency.starting') }} {{ formatter.formatDate(new Date(form.start_at), 'yyyy-MM-dd') }}
                  </div>
                </div>
              </div>

            </AccordionHeader>
            <AccordionContent>
              <p class="m-0">
                <FrequencyForm @complete-step="completeStep" />
              </p>
            </AccordionContent>
          </AccordionPanel>
          <AccordionPanel value="email" :disabled="state.emailDisabled">
            <AccordionHeader>
               <span class="flex items-center gap-2">
                <i class="pi pi-envelope"></i>
                {{ $t('alerts.email.heading') }}
              </span>
            </AccordionHeader>
            <AccordionContent>
              <EmailForm @complete-step="completeStep" />
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

  <div class="flex my-8 justify-between">
    <div>
      <DeleteConfirmButton v-if="alert?.uuid" @delete="deleteAlert" />
    </div>
    <div class="flex gap-4 justify-end">
      <Link class="md:items-center flex" :href="route('ui.alerts.index', { customer: customer.uuid })" v-ripple :method="'get'">
        <Button :label="$t('global.buttons.cancel')" text severity="secondary"/>
      </Link>
      <form @submit.prevent="emits('submit')">
        <Button
          :disabled="!state.requiredStepsComplete"
          severity="primary"
          :label="alert === undefined ? $t('alerts.actions.create_activate') : $t('global.buttons.save')"
          type="submit"
        />
      </form>
    </div>
  </div>

</template>
