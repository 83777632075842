import { type Reactive, ref } from 'vue';

import TranslatorService from '@/Utils/Translations/TranslatorService';
import { Translations } from './Translations';
import type {
  Replacements,
  TranslationKey,
  TranslationsType,
} from '@/Utils/Translations/types';
import type { LanguageCode } from '@/types';
import type { Page } from '@inertiajs/core';

const translatorService = ref<TranslatorService<TranslationsType>>();
const language = ref<LanguageCode>();

export function useTranslate() {
  const updateLanguage = (newLang: LanguageCode, page: Reactive<Page>) => {
    translatorService.value = new TranslatorService(Translations, newLang as keyof TranslationsType);
    language.value = newLang;
    if (page.props.auth.user?.language) {
      page.props.auth.user.language.code = newLang;
      page.props.auth.user.language.locale = newLang;
    }
  };

  const translate = <S extends TranslationKey>(
    key: S,
    replacements: Replacements<S> = undefined as Replacements<S>,
  ): string => {
    return translatorService.value?.translate(key, replacements) ?? '';
  };

  return { translate, updateLanguage, language };
}
