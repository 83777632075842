<script setup lang="ts">
import { isLowRating, isModerateRating } from '@/Pages/Domains/Score/ScoreUtils';

const { score } = defineProps<{
  score: number;
}>();

const rating = Math.round((score / 20));

const classes = ((rating) => {
  if (isLowRating(rating)) {
    return 'bg-success-background text-success-foreground';
  } else if (isModerateRating(rating)) {
    return 'bg-warn-background text-warn-foreground';
  } else {
    return 'bg-danger-background text-danger-foreground';
  }
})(rating);

</script>
<template>
  <div
    class="rounded w-16 flex items-center justify-center h-16"
    :class="classes"
  >
    <div class="flex items-baseline whitespace-nowrap">
      <div class="text-2xl font-semibold" v-text="rating" />
      <span class="text-sm">/5</span>
    </div>
  </div>
</template>
