<script setup lang="ts">
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import { useFormatters } from '@/Utils/Formatting';
import Card from 'primevue/card';
import Column from 'primevue/column';
import Avatar from 'primevue/avatar';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from '@primevue/core/api';
import { SenderCategory } from '@/types/types.gen';
import axios from 'axios';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useToast } from 'primevue/usetoast';
import { reactive, watch } from 'vue';
import { useRoute } from 'ziggy-js';
import type { ReportEmits, ReportTableProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import SenderCategorySelector from '@/Pages/Statistics/Sender/Partials/SenderCategorySelector.vue';

const { formatNumber, titleCase } = useFormatters();
const { translate } = useTranslate();
const toast = useToast();

const props = defineProps<ReportTableProps>();
const emit = defineEmits<ReportEmits>();

const getSenderName = (stat: GroupedStatistics) => (stat.sender_uuid ? stat.organisation : stat.asn.toString()) ?? 'Unknown';
const makeTableGroups = (groups: GroupedStatistics[]) => groups.map(
  group => ({ ...group, expanded: false }),
);

const state = reactive({
  groups: makeTableGroups(props.groups),
  categorySaving: {} as Record<string, boolean>,
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

watch(
  () => props.groups,
  newGroups => state.groups = makeTableGroups(newGroups),
  { deep: true },
);

const updateCategory = async (senderGroup: GroupedStatistics, category: SenderCategory) => {
  if (!senderGroup.sender_uuid) {
    return;
  }

  try {
    state.categorySaving[senderGroup.sender_uuid] = true;
    await axios.put(useRoute()('ui.domain-senders.update', {
      customer: props.customerUuid,
      sender: senderGroup.sender_uuid,
      domain: props.domain.uuid,
    }), { category });
    toast.add({
      detail: translate('senders_reports.messages.category_updated.success'),
      life: 3000,
      severity: 'success',
      summary: translate('global.created'),
    });
    emit('change-category', senderGroup, category);
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: translate('senders_reports.messages.category_updated.error'),
    });
    console.error(error);
  } finally {
    state.categorySaving[senderGroup.sender_uuid] = false;
  }
};

</script>

<template>
  <Card>
    <template #content>
      <DataTable
        v-model:filters="state.filters"
        :value="state.groups"
        :rows="10"
        data-key="expanded"
        :expanded-rows="state.groups"
        @row-expand="(group) => group.expanded = true"
        @row-collapse="(group) => group.expanded = false"
        sort-field="total_incidents"
        :sort-order=-1
        :global-filter-fields="['organisation', 'category', 'service']"
        tableStyle="min-width: 80rem"
      >
        <template #empty>{{ $t('tables.not_found') }}</template>
        <template #header>
          <div class="flex justify-between">
            <IconField>
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                v-model="state.filters.global.value"
                :placeholder="$t('tables.search')"
              />
            </IconField>

            <div class="flex gap-6 text-sm bg-section-default px-4 rounded">
              <slot name="legend"/>
            </div>
          </div>
        </template>

        <Column expander/>
        <Column :header="$t('senders_reports.senders.table.header.sender')" field="organisation" :sortable="true">
          <template #body="{data}: {data: GroupedStatistics}">
            <div class="flex items-center">
              <Avatar
                v-if="!data.sender_logo"
                :label="getSenderName(data)[0]"
                size="large"
              />
              <Avatar
                v-else
                :image="data.sender_logo"
                :pt="{image: 'rounded'}"
                size="large"
              />
              <div class="flex flex-col ml-4">
                <div class="leading-5 text-lg text-color">
                  {{ getSenderName(data) }}
                </div>
                <div class="font-light">
                  {{ data.service === 'isp' ? 'ISP' : titleCase(data.service ?? 'Unknown') }}
                </div>
              </div>
            </div>

          </template>
        </Column>

        <Column :header="$t('senders_reports.senders.table.header.ip_addresses')" field="total_sender_ip_addresses" :sortable="true">
          <template #body="{data}: {data: GroupedStatistics}">
            {{ formatNumber(data.total_sender_ip_addresses) }}
          </template>
        </Column>

        <slot name="columns"/>

        <Column :header="$t('senders_reports.senders.table.header.category')" field="category" :sortable="true">
          <template #body="{data}: {data: GroupedStatistics}">
            <SenderCategorySelector
              :category="data.category"
              :is-saving="!!data.sender_uuid && state.categorySaving[data.sender_uuid]"
              :has-sender="!!data.sender_uuid"
              @update-category="updateCategory(data, $event)"
            />
          </template>
        </Column>

        <template #expansion="{data}: {data: GroupedStatistics}">
          <slot name="sub-table" :data="data" />
        </template>
      </DataTable>
    </template>
  </Card>
</template>
