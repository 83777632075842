export const Translations = {
    "de": {
        "alerts": {
            "actions": {
                "create_activate": "Erstellen und aktivieren",
                "edit_alert": {
                    "tooltip": "Benachrichtigung bearbeiten"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Konfiguration",
                    "mail_senders": "E-Mail-Absender",
                    "none": "Keiner",
                    "reports": "Berichte",
                    "status": "Status"
                },
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Aktivität",
                "options": {
                    "customer-created": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto erstellt wird.",
                        "heading": "Kunde erstellt"
                    },
                    "customer-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn ein Konto gelöscht wird.",
                        "heading": "Kunde gelöscht"
                    },
                    "customer-exposure-report": {
                        "description": "Planen Sie, wann Sie den Expositionsbericht erhalten möchten.",
                        "heading": "Expositionsbericht"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planen Sie ein DMARC-Compliance-Zertifikat, wenn die DMARC-Richtlinie auf „Ablehnen“ eingestellt ist.",
                        "heading": "DMARC-Compliance-Zertifikat bei Ablehnung"
                    },
                    "dns-delegation-unverified": {
                        "description": "Benachrichtigen Sie mich, wenn die erneute Überprüfung der DNS-Delegierung fehlschlägt.",
                        "heading": "Die erneute Überprüfung des Setups ist fehlgeschlagen"
                    },
                    "dns-delegation-verified": {
                        "description": "Benachrichtigen, an welcher Stelle sich der Verifizierungsstatus geändert hat",
                        "heading": "Verifizierungsstatus geändert"
                    },
                    "domain-compliance-drop": {
                        "description": "Benachrichtigen Sie mich, wenn die Compliance unter einen Grenzwert fällt.",
                        "heading": "Compliance gesunken"
                    },
                    "domain-created": {
                        "description": "Benachrichtigen Sie mich, wenn eine neue Domain hinzugefügt wird.",
                        "heading": "Domain erstellt"
                    },
                    "domain-deleted": {
                        "description": "Benachrichtigen Sie mich, wenn eine Domain gelöscht wird.",
                        "heading": "Domain gelöscht"
                    },
                    "domain-score-change": {
                        "description": "Benachrichtigen Sie mich, wenn sich der DMARC-Compliance-Score geändert hat.",
                        "heading": "DMARC-Compliance-Score geändert"
                    },
                    "domain-settings-change": {
                        "description": "Benachrichtigen Sie mich, wenn Einstellungen geändert werden.",
                        "heading": "Änderungen an Einstellungen"
                    },
                    "sender-threat-level-change": {
                        "description": "Benachrichtigen Sie mich, wenn die Bedrohungsstufe für neue oder bestehende Absender steigt.",
                        "heading": "Bedrohungsstufe erhöht"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Benachrichtigen Sie mich, wenn ein neuer, allgemein genehmigter Absender erkannt wird.",
                        "heading": "Genehmigter Absender gefunden"
                    }
                }
            },
            "create": {
                "title": "Benachrichtigung erstellen"
            },
            "edit": {
                "title": "Benachrichtigung bearbeiten"
            },
            "email": {
                "description": "Geben Sie den E-Mail-Empfänger an, den Sie benachrichtigen möchten, und passen Sie die E-Mail an.",
                "heading": "E-Mail"
            },
            "email_settings": {
                "heading": "E-Mail anpassen"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Kunde",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequenz"
                },
                "recipients": {
                    "input_label": "Empfänger"
                },
                "scope": {
                    "input_label": "Überwachungsebene",
                    "listing-title": ":scope Aktivität",
                    "options": {
                        "customer": "Kunde",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup-Überprüfung",
                    "input_label": "Wählen Sie Unten aus"
                },
                "start_date": {
                    "input_label": "Startdatum"
                },
                "status": {
                    "input_label": "Status"
                },
                "subject_tags": {
                    "example": "z.B. [Schwere der Benachrichtigung][Konto][Paket][Domain]",
                    "input_label": "Aktivieren Sie Präfixe für die Kategorisierung der Betreffzeile"
                }
            },
            "frequency": {
                "description": "Wählen Sie aus, wie oft Sie diese Benachrichtigung erhalten möchten.",
                "disabled-text": "Nicht verfügbar – Dies ist eine ereignisbasierte Aktivität",
                "heading": "Frequenz",
                "options": {
                    "monthly": "Monatlich",
                    "quarterly": "Vierteljährlich",
                    "six_monthly": "Sechsmonatlich",
                    "two_monthly": "Zweimonatlich",
                    "yearly": "Jährlich"
                },
                "starting": "Beginnt"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Benachrichtigung",
                        "frequency": "Frequenz",
                        "recipients": "Empfänger",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Aktivität"
                    }
                },
                "title": "Benachrichtigung"
            },
            "monitor": {
                "description": "Wählen Sie die Aktivität aus, die Sie überwachen möchten.",
                "heading": "Überwachung"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Täglich",
                        "fortnightly": "Alle zwei Wochen",
                        "hourly": "Stündlich",
                        "immediate": "Sofort",
                        "monthly": "Monatlich",
                        "quarterly": "Vierteljährlich",
                        "six_monthly": "Sechs monatlich",
                        "two_monthly": "Zwei monatlich",
                        "weekly": "Wöchentlich",
                        "yearly": "Jährlich"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Benachrichtigung ist inaktiv",
                        "value": "aus"
                    },
                    "on": {
                        "tooltip": "Benachrichtigung ist aktiv",
                        "value": "an"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deaktivieren Sie das BIMI-Datensatz-Hosting",
                "heading": "BIMI-Datensatz-Hosting ist derzeit aktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat einfach verwalten zu können.",
                "modal": {
                    "header": "Deaktivieren Sie BIMI-Datensatz-Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie BIMI-Datensatz-Hosting",
                "heading": "BIMI-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, BIMI Hosting zu aktivieren, um Ihr Logo und Zertifikat zu verwalten.",
                "modal": {
                    "header": "Aktivieren Sie BIMI-Datensatz-Hosting :domain",
                    "message": "Durch die Aktivierung von BIMI Hosting können Sie Ihr Logo und Zertifikat einfach verwalten."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "CN-Zertifikat",
                    "expiry": "Ablauf des Zertifikats",
                    "input_label": "BIMI Verified Mark-Zertifikat (Dateityp: pem)",
                    "url": "Autoritäts-\/Zertifikats-URL"
                },
                "image": {
                    "input_label": "BIMI Logo (Dateityp: SVG)",
                    "preview": "Logovorschau",
                    "size": "Logogröße",
                    "url": "Logo-URL"
                }
            },
            "info": {
                "heading": "Was ist BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) ist ein Standard, der es einfacher macht, Ihr Logo neben Ihren Nachrichten im Posteingang anzuzeigen, betrügerische E-Mails zu verhindern und die Zustellbarkeit zu verbessern. BIMI arbeitet mit DMARC, DKIM und SPF zusammen, um die Authentizität einer E-Mail zu überprüfen und die Markensichtbarkeit zu verbessern."
            },
            "settings": {
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Zurück zu den Einstellungen",
                "share": "Setup-Anweisungen teilen",
                "verify": "Verifizieren",
                "view": "Setup-Anweisungen anschauen"
            },
            "bimi": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der öffentlichen BIMI-Schlüssel Ihrer Domain zu ermöglichen.",
                "modal": {
                    "not_configured": {
                        "header": "Einrichtungsanleitung nicht verfügbar",
                        "message": "Um DNS-Einträge für Ihre BIMI-Konfiguration zu generieren, müssen Sie ein Logo hochladen."
                    }
                }
            },
            "description": "Um die Überwachung und Verwaltung über DMARC Manager zu ermöglichen, nehmen Sie bitte die folgenden Änderungen in den DNS-Einstellungen Ihrer Domain vor.",
            "dkim": {
                "description": "Fügen Sie die folgenden Einträge zu den DNS-Einträgen Ihrer Domain hinzu, um die Konfiguration ihrer öffentlichen DKIM-Schlüssel zu ermöglichen.",
                "no_nameservers": "Die DKIM-öffentliche Schlüsselzone wird erstellt. Bitte warten Sie auf die Einrichtungshinweise..."
            },
            "dmarc": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration der DMARC-Richtlinie Ihrer Domain zu ermöglichen und Berichte über E-Mail-Authentifizierungsaktivitäten über unser Dashboard zu erfassen.",
                "warning": "Beachten Sie die erforderlichen Unterstriche (_) im Host und die Punkte im Datensatzwert."
            },
            "existing_dns_record_found": "Vorhandener :record gefunden",
            "heading": ":title Setup-Anweisungen",
            "instructions": {
                "add": ":rdType Datensatz hinzufügen",
                "heading": ":rdType Einrichtungsanweisungen",
                "new": "Schritt 2: Neuen :rdType Eintrag hinzufügen",
                "remove": "Schritt 1: Vorhandenen :rdType Eintrag entfernen"
            },
            "mta_policy": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration der MTA-Richtlinie Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Richtlinie"
            },
            "mta_record": {
                "description": "Aktualisieren Sie Ihren DNS, um die Konfiguration des MTA-Eintrags Ihrer Domain zu ermöglichen.",
                "heading": "MTA-STS-Eintrag"
            },
            "record": {
                "host": "Gastgeber",
                "type": "Typ des Eintrags",
                "value": "Wert"
            },
            "spf": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration des SPF-Eintrags Ihrer Domain über unsere Plattform zu ermöglichen. Unsere Plattform stellt die Gültigkeit des Eintrags sicher und nutzt Optimierungstechnologien, die das Risiko des Erreichens von DNS-Suchgrenzen verringern und sicherstellen, dass Ihre Richtlinie nicht zu durchlässig ist."
            },
            "status": {
                "last_date": "Zuletzt verifiziert am",
                "pending": "Ausstehende Verifizierung",
                "verified": "Verifiziert"
            },
            "tls_rpt": {
                "description": "Aktualisieren Sie Ihr DNS, um die Konfiguration des TLS-RPT-Eintrags Ihrer Domain zu ermöglichen und Berichte zu erfassen.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Kunden verwalten"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "E-Mail-Volumen",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt & Sonstiges"
                    }
                },
                "domain_totals": {
                    "action": "Domains anzeigen",
                    "enforced": "Erzwungen",
                    "heading": "Domains",
                    "reporting": "Reporting",
                    "total": "Gesamt"
                },
                "sender_totals": {
                    "action": "Absender anzeigen",
                    "heading": "Absender",
                    "legends": {
                        "approved": "Genehmigt",
                        "unapproved": "Nicht genehmigt"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Alle Domains anschauen"
                },
                "legends": {
                    "failing": "DMARC fehlgeschlagen",
                    "forwards": "Weiterleiten",
                    "passing": "DMARC bestanden"
                },
                "table": {
                    "action": {
                        "view_report": "Bericht anschauen"
                    },
                    "header": {
                        "approved": "Genehmigt",
                        "domain": "Domains",
                        "senders": "Absender",
                        "unapproved": "Nicht genehmigt",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Domainbericht anschauen"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "domain": "Domain",
                        "sender": "Zugelassene Absender nach Volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Dashboard"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert stimmt nicht mit dem erwarteten Wert überein.",
                        "missing": "Es konnte kein übereinstimmender CNAME-Eintrag gefunden werden."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "Es konnten keine übereinstimmenden Nameserver gefunden werden.",
                        "mismatch": "Die Nameserver-Einträge stimmen nicht mit der erwarteten Konfiguration überein.",
                        "missing": "Es müssen mindestens zwei Nameserver-Einträge vorhanden sein."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "Der DKIM-Alignmentmodus stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der DKIM-Alignmentmodus fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "aspf": {
                        "mismatch": "Der SPF-Alignmentmodus stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der SPF-Alignmentmodus fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "cname": {
                        "mismatch": "Der CNAME-Eintrag wurde nicht ordnungsgemäß hinzugefügt und der Eintrag stimmt nicht mit der erwarteten Konfiguration überein.",
                        "partial": "Der CNAME-Eintrag wurde nicht ordnungsgemäß hinzugefügt. Dies kann zu Konflikten bei Konfigurationen führen."
                    },
                    "fo": {
                        "mismatch": "Die Fehlerberichtoptionen stimmen nicht mit der Konfiguration überein.",
                        "missing": "Die Fehlerberichtoptionen fehlen, sind jedoch nicht auf den Standard konfiguriert."
                    },
                    "p": {
                        "mismatch": "Die Richtlinie stimmt nicht mit der Konfiguration überein.",
                        "missing": "Die Richtlinie fehlt."
                    },
                    "pct": {
                        "mismatch": "Der Richtlinienanteil stimmt nicht mit der Konfiguration überein.",
                        "missing": "Der Richtlinienanteil fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "rf": {
                        "mismatch": "Das Format der Fehlerberichterstattung stimmt nicht mit der Konfiguration überein."
                    },
                    "ri": {
                        "mismatch": "Das Intervall für aggregierte Berichte stimmt nicht mit der Konfiguration überein.",
                        "missing": "Das Intervall für aggregierte Berichte fehlt, ist jedoch nicht auf den Standard konfiguriert."
                    },
                    "rua": {
                        "mismatch": "Die Empfänger der aggregierten Berichte stimmen nicht mit der Konfiguration überein.",
                        "order": "Die Empfänger der aggregierten Berichte sind nicht in der richtigen Reihenfolge."
                    },
                    "ruf": {
                        "mismatch": "Die Empfänger der Fehlerberichte stimmen nicht mit der Konfiguration überein.",
                        "order": "Die Empfänger von Fehlerberichten befinden sich nicht in der korrekten Reihenfolge."
                    },
                    "sp": {
                        "mismatch": "Die Subdomain-Richtlinie stimmt nicht mit der Konfiguration überein.",
                        "missing": "Die Subdomain-Richtlinie fehlt, ist aber im Vergleich mit der Domain-Richtlinie anders konfiguriert."
                    },
                    "txt": {
                        "reporting-only": "Der Eintrag stimmt nicht mit der erwarteten Konfiguration überein, aber unsere Berichtsadresse ist vorhanden."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert entspricht nicht dem erwarteten Wert.",
                        "missing": "Kein übereinstimmender CNAME-Eintrag konnte gefunden werden."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "Der TXT-Wert entspricht nicht dem erwarteten Wert."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "Der CNAME-Wert entspricht nicht dem erwarteten Wert.",
                        "missing": "Es konnte kein übereinstimmender CNAME-Eintrag gefunden werden."
                    },
                    "rua": {
                        "missing": "Die erwartete Berichtsadresse fehlt."
                    }
                }
            },
            "verify": {
                "error": "Verifizierung fehlgeschlagen",
                "nxdomain": "Eintrag nicht gefunden.",
                "success": "Verifizierung erfolgreich abgeschlossen",
                "timeout": "Verifizierung abgelaufen"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Neuen öffentlichen Schlüssel hinzufügen",
                "edit_public_key": {
                    "tooltip": "Öffentlichen Schlüssel bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neuen öffentlichen Schlüssel hinzufügen"
            },
            "disable": {
                "action": "DKIM-Management deaktiviert",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist derzeit aktiviert",
                "message": "Es wird empfohlen, die DKIM-Verwaltung zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "DKIM-Management für :domain deaktivieren",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "edit_drawer": {
                "header": "Öffentlichen Schlüssel bearbeiten"
            },
            "enable": {
                "action": "DKIM-Management aktivieren",
                "heading": "Das Hosting öffentlicher DKIM-Schlüssel ist deaktiviert",
                "message": "Es wird empfohlen, das DKIM-Management zu aktivieren, um Sie bei der Verwaltung Ihrer öffentlichen Schlüssel zu unterstützen.",
                "modal": {
                    "header": "DKIM-Management für :domain aktivieren",
                    "message": "Durch die Aktivierung des DKIM-Managements können Sie Ihre öffentlichen Schlüssel ganz einfach verwalten."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "record_type": {
                    "input_label": "Typ des Eintrags"
                },
                "record_value": {
                    "example": "z.B. dkim.example.com",
                    "input_label": "Wert des Eintrags"
                },
                "selector": {
                    "input_label": "Selektor"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "Import des öffentlichen DKIM-Schlüssels abgeschlossen"
                },
                "disabled_tooltip": "Öffentliche DKIM-Schlüssel können nur für Domains importiert werden, die über Berichte verfügen.",
                "header": "Importieren Sie die öffentlichen DKIM-Schlüssel für :domain erneut",
                "message": "Alle vorhandenen öffentlichen Schlüssel werden entfernt und durch importierte Anweisungen ersetzt."
            },
            "info": {
                "heading": "Was ist DKIM?",
                "message": "Domain Keys Identified Mail ist eine E-Mail-Authentifizierungsmethode, die es einem Absender ermöglicht, eine E-Mail mit einem privaten Schlüssel digital zu signieren. Der private Schlüssel ist dabei mit dem Domainnamen des Absenders verknüpft ist und kann durch den E-Mail-Server des Empfängers über einen öffentlichen Schlüssel überprüft werden, der in den DNS-Einträgen des Absenders veröffentlicht ist. Dies hilft, die Authentizität und Integrität der E-Mail zu überprüfen und das Risiko von Spam- und Phishing-Angriffen zu verringern.“"
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Richtlinie für ausgehende Signaturen",
                        "options": {
                            "all": "Alle E-Mails",
                            "some": "Einige E-Mails"
                        }
                    },
                    "testing": {
                        "input_label": "Testmodus"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Typ des Eintrags",
                        "selector": "Selektor",
                        "value": "Wert"
                    }
                }
            },
            "title": "Einstellungen bearbeiten – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM-Abgleichmodus"
                    },
                    "options": {
                        "r": "Entspannt",
                        "s": "Strikt"
                    },
                    "spf_mode": {
                        "input_label": "SPF-Abgleichmodus"
                    }
                },
                "heading": "Abgleicheinstellungen"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Unterschiedliche Subdomain-Richtlinie anwenden"
                    },
                    "percentage": {
                        "input_label": "Prozentsatz"
                    },
                    "policy": {
                        "input_label": "DMARC-Richtlinie"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain-Richtlinie"
                    }
                },
                "heading": "Richtlinieneinstellungen"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Berichte senden an"
                    },
                    "interval": {
                        "input_label": "Berichtsintervall",
                        "options": {
                            "1_day": "Einmal am Tag",
                            "1_hour": "Stündlich",
                            "2_hours": "Alle 2 Stunden",
                            "4_hours": "Alle 4 Stunden",
                            "6_hours": "Alle 6 Stunden"
                        }
                    }
                },
                "heading": "Aggregierte Berichte"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Aktivieren",
                    "header": "Fehlerberichterstattung aktivieren",
                    "message": "Durch die Aktivierung der Fehlerberichterstattung können einige persönliche Informationen, wie E-Mail-Adressen oder Nachrichteninhalte, zu Diagnosezwecken offengelegt werden",
                    "reject": "Deaktivieren"
                },
                "fields": {
                    "options": {
                        "input_label": "Berichtsoptionen",
                        "options": {
                            "d": "DKIM schlägt unabhängig vom Abgleich fehl",
                            "s": "SPF schlägt unabhängig vom Abgleich fehl",
                            "0": "SPF oder DKIM schlagen fehl und stimmen nicht überein",
                            "1": "SPF und DKIM schlagen fehl und stimmen nicht überein"
                        }
                    },
                    "status": {
                        "input_label": "Berichtsverarbeitung",
                        "options": {
                            "disabled": "Deaktiviert",
                            "enabled": "Aktiviert",
                            "specified": "Spezifische Empfänger"
                        }
                    }
                },
                "heading": "Fehlerberichte"
            },
            "title": "Einstellungen bearbeiten – DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC-Compliance-Zertifikat",
                "domain_report": "Domain-Bericht",
                "edit_settings": {
                    "tooltip": "Einstellungen bearbeiten"
                },
                "remove_primary": "Aus Favoriten entfernen",
                "set_as_active": "Als „Aktiv festlegen",
                "set_as_parked": "Als „Geparkt“ festlegen",
                "set_primary": "Als „Favorit markieren",
                "view_logs": "Protokolle anschauen"
            },
            "create_drawer": {
                "header": "Neue Domain hinzufügen"
            },
            "fields": {
                "name": {
                    "example": "z.B. „example.com“ oder „subdomain.example.com“",
                    "input_label": "Domainname"
                },
                "type": {
                    "input_label": "Domaintyp",
                    "options": {
                        "active": "Aktiv",
                        "parked": "Geparkt"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Richtlinie",
                        "score": "Score",
                        "status": "Status",
                        "type": "Typ"
                    }
                },
                "title": "Domains"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "report_modal": {
                "action": "Link generieren",
                "fields": {
                    "copy_link": {
                        "input_label": "Kopieren Sie den Link unten, um den Domain-Bericht zu teilen"
                    },
                    "date_range": {
                        "input_label": "Datumsbereich"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einem Tag",
                            "1_month": "Einem Monat",
                            "1_week": "Einer Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Domain-Bericht"
            },
            "settings": {
                "title": "Einstellungen bearbeiten"
            },
            "setup_modal": {
                "action": "Link generieren",
                "fields": {
                    "copy_link": {
                        "input_label": "Link zu den Einrichtungsanweisungen"
                    },
                    "expire_link": {
                        "input_label": "Link ablaufen lassen in",
                        "options": {
                            "1_day": "Einem Tag",
                            "1_month": "Einem Monat",
                            "1_week": "Einer Woche",
                            "6_hours": "6 Stunden",
                            "12_hours": "12 Stunden",
                            "never": "Niemals"
                        }
                    }
                },
                "header": "Link zu den Setup-Anweisungen"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Keiner"
                    },
                    "quarantine": {
                        "value": "Quarantäne"
                    },
                    "reject": {
                        "value": "Ablehnen"
                    },
                    "tooltip": "Richtlinie angewendet"
                },
                "primary": {
                    "tooltip": "Favorit",
                    "value": "Favorit"
                },
                "score": {
                    "high": {
                        "tooltip": "Hohes Risiko"
                    },
                    "low": {
                        "tooltip": "Geringes Risiko"
                    },
                    "moderate": {
                        "tooltip": "Mittleres Risiko"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verifiziert, keine Meldung",
                        "tooltip_partial": "Teilweise verifiziert, keine Meldung",
                        "value": "Keine Meldung"
                    },
                    "not_verified": {
                        "tooltip": "Nicht verifiziert, keine Meldung",
                        "value": "Nicht verifiziert"
                    },
                    "reporting": {
                        "tooltip": "Verifiziert, Meldung",
                        "tooltip_partial": "Teilweise verifiziert, Meldung",
                        "value": "Meldung"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Aktive Domain",
                        "value": "Aktiv"
                    },
                    "parked": {
                        "tooltip": "Geparkte Domain",
                        "value": "Geparkt"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "z.B. empfänger@example.com",
                    "input_label": "E-Mail bearbeiten",
                    "placeholder": "E-Mail-Adresse eingeben",
                    "subscribed_label": "Abonniert",
                    "unsubscribed_label": "Abgemeldet",
                    "validation_message": "Gültige E-Mail-Adresse eingeben"
                }
            }
        },
        "errors": {
            "menu": "Fehler",
            "401": {
                "description": "Sie nicht dazu berechtigt, auf diese Seite zuzugreifen.",
                "title": "401: Nicht autorisiert"
            },
            "403": {
                "description": "Sie dürfen nicht auf diese Seite zugreifen.",
                "title": "403: Verboten"
            },
            "404": {
                "description": "Die von Ihnen gesuchte Seite konnte nicht gefunden werden.",
                "title": "404: Seite nicht gefunden"
            },
            "500": {
                "description": "Hoppla, auf unseren Servern ist ein Fehler aufgetreten.",
                "title": "500: Serverfehler"
            },
            "502": {
                "description": "Leider sind unsere Server ausgefallen.",
                "title": "502: Bad Gateway"
            },
            "503": {
                "description": "Es tut uns leid, wir führen Wartungsarbeiten durch. Bitte versuchen Sie es später erneut.",
                "title": "503: Dienst nicht verfügbar"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "E-Mail-Volumen",
                "legends": {
                    "failing": "DMARC schlägt fehl",
                    "forward": "Vorwärts",
                    "passing": "DMARC bestanden"
                }
            },
            "heading": "Domainbericht",
            "recommendations": {
                "heading": "Empfehlungen"
            },
            "risk_analysis": {
                "heading": "Risikoanalyse"
            },
            "scoring": {
                "note": "Hinweis: Der Domain-Score wird zum Zeitpunkt der Erstellung des Berichts berechnet und ist zum Zeitpunkt der Anzeige möglicherweise nicht aktuell."
            },
            "sender_readiness": {
                "heading": "DMARC-Bereitschaft des Absenders",
                "legends": {
                    "configured": {
                        "description": "Die DMARC-Bereitschaft ist ein Maß für die Konfiguration, die zur Implementierung vollständiger Compliance erforderlich ist.",
                        "heading": "Konfiguriert"
                    },
                    "partially": {
                        "description": "Bei teilweise konfigurierten Quellen ist mindestens ein Autorisierungsmechanismus aktiv. Möglicherweise ist jedoch eine zusätzliche Einrichtung erforderlich.",
                        "heading": "Teilweise konfiguriert"
                    },
                    "unconfigured": {
                        "description": "Unzulässige oder nicht konfigurierte Quellen sind Quellen, die E-Mails von Ihrer Domain senden und möglicherweise Ihre Organisation imitieren.",
                        "heading": "Unzulässig oder nicht konfiguriert"
                    }
                }
            },
            "title": "Domainbericht für :domain",
            "top_countries": {
                "heading": "Top-Absenderländer – E-Mail-Volumen"
            },
            "top_senders": {
                "heading": "Top-Absender – Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Bericht herunterladen",
                "header": "Fehlerbericht",
                "menu": {
                    "key_properties": "Schlüsseleigenschaften",
                    "more_info": "Weitere Informationen"
                }
            },
            "properties": {
                "authentication_failure": "Authentifizierungsfehler",
                "date": "Datum",
                "delivery_results": "Lieferergebnisse",
                "dkim_domain": "DKIM-Domain",
                "dkim_header": "DKIM-kanonisierter Header",
                "dkim_identity": "DKIM-Identität",
                "dkim_selector": "DKIM-Selektor",
                "dmarc_version": "DMARC-Version",
                "feedback_type": "Feedback-Typ",
                "incidents": "Anzahl der Vorfälle",
                "original_recipients": "Ursprüngliche Empfänger",
                "reporting_domains": "Gemeldete Domains",
                "reporting_mta": "MTA melden",
                "reporting_user_agent": "Meldender Benutzeragent",
                "return_path": "Rückweg",
                "source_ip": "Ursprüngliche IP-Adresse",
                "spf_dns": "SPF-DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentifizierung",
                    "date": "Datum",
                    "ip_address": "IP-Adresse",
                    "result": "Ergebnis",
                    "return_path": "Return-Path"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Zugestellt",
                    "none": "Keine, akzeptieren",
                    "smg_policy_action": "Angewendete Richtlinie",
                    "spam": "Abgelehnt",
                    "unknown": "Unbekannt"
                }
            },
            "title": "Fehlerberichte für :domain"
        },
        "global": {
            "buttons": {
                "add": "Hinzufügen",
                "add_new": "Neu hinzufügen",
                "back": "Zurück",
                "cancel": "Abbrechen",
                "delete": "Löschen",
                "import": "Importieren",
                "next": "Nächste",
                "no": "Nein",
                "open_link": "Link öffnen",
                "remove": "Entfernen",
                "save": "Speichern",
                "save_exit": "Speichern und zurück",
                "select": "Auswählen",
                "yes": "Ja"
            },
            "confirm_delete": "Möchten Sie fortfahren?",
            "copied": "Kopiert",
            "created": "Erstellt",
            "deleted": "Gelöscht",
            "fail": "Fehler",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Deaktiviert",
                        "enabled": "Aktiviert"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Aus",
                        "on": "An"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "Nein",
                        "yes": "Ja"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Fehler: Bitte füllen Sie alle erforderlichen Felder korrekt aus.",
                    "title": "Es sind Fehler in Ihrem Formular aufgetreten."
                },
                "success": {
                    "title": "Ihre Änderungen wurden gespeichert."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Abmelden",
            "not_found": "Nicht gefunden",
            "pagination": {
                "of": "von",
                "showing": "Zeigt",
                "to": "Zu"
            },
            "pass": "Bestanden",
            "remove": "Entfernen",
            "show_less": "Weniger anzeigen",
            "show_more": "Mehr anzeigen",
            "system": "System",
            "theme": {
                "dark": "Dunkel",
                "light": "Licht"
            },
            "to": "Zu"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Datumsbereich",
                "to": "An"
            },
            "menu": {
                "overrides": "Überschreibt",
                "overview": "Überblick",
                "reporters": "Reporter",
                "results": "Ergebnisse"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Angewendete Richtlinie „Keine“",
                    "policy_applied_quarantine": "Angewendete Richtlinie „Quarantäne“",
                    "policy_applied_reject": "Angewendete Richtlinie „Ablehnen“"
                },
                "overridden_policy_breakdown": "Aufschlüsselung der überschriebenen Richtlinie",
                "volume_overridden_policy": "Volumen mit überschriebenen Richtlinien"
            },
            "overview": {
                "cards": {
                    "compliance": "Compliance",
                    "deliverability": "Zustellbarkeit",
                    "volume": "Volumen"
                },
                "properties": {
                    "country": "Land",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Absender"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "envelope_from": "Envelope From",
                        "envelope_to": "Envelope To",
                        "header_from": "Header From",
                        "no_data": "Keine Reporterdaten verfügbar",
                        "percent_of_total": "% der Gesamtmenge",
                        "reporter": "Reporter",
                        "volume": "Volumen"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Übereinstimmung",
                    "error": "Fehler",
                    "fail": "Fehlgeschlagen",
                    "failed": "Fehlgeschlagen\/Keine Übereinstimmung",
                    "none": "Keiner",
                    "pass": "Bestanden",
                    "passed": "Bestanden\/Übereinstimmend"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Aktion angewendet",
                    "info": {
                        "tooltip": "Vom Empfänger ergriffene Maßnahmen"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Zugestellt",
                            "value": "Zugestellt"
                        },
                        "quarantine": {
                            "tooltip": "Unter Quarantäne gestellt",
                            "value": "Unter Quarantäne gestellt"
                        },
                        "reject": {
                            "tooltip": "Abgelehnt",
                            "value": "Abgelehnt"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Übereinstimmung",
                            "dmarc_via_dkim": "DMARC über DKIM",
                            "results": "Ergebnisse",
                            "selector": "Selektor",
                            "signing_domain": "Signierende Domain"
                        },
                        "heading": "DKIM-Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM nicht übereinstimmend",
                            "no_info": "Keine DKIM-Informationen",
                            "yes": "DKIM übereinstimmend"
                        },
                        "header": "DKIM-Ergebnisse",
                        "no_details": "Keine DKIM-Signaturdetails",
                        "pass_tooltips": {
                            "no": "DKIM fehlgeschlagen",
                            "none": "DKIM Keine",
                            "yes": "DKIM bestanden"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "Die Richtlinie :policy wurde vom Empfänger angewendet"
                        },
                        "header": {
                            "from_domain": "From Domain"
                        },
                        "heading": "Weitere Details"
                    },
                    "results": {
                        "header": "DMARC-Ergebnisse",
                        "pass_tooltips": {
                            "no": "DMARC fehlgeschlagen",
                            "yes": "DMARC bestanden"
                        }
                    }
                },
                "override_comment": {
                    "header": "Kommentar"
                },
                "override_type": {
                    "header": "Überschreibungstyp"
                },
                "published_policy": {
                    "header": "Veröffentlichte Richtlinie",
                    "info": {
                        "tooltip": "DMARC-Richtlinie zum Zeitpunkt der Validierung"
                    }
                },
                "reporter": {
                    "header": "Reporter"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Übereinstimmung",
                            "dmarc_via_spf": "DMARC über SPF",
                            "result": "Ergebnis",
                            "return_path_domain": "Rückweg-Domain"
                        },
                        "heading": "SPF-Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF nicht übereinstimmend",
                            "no_info": "Keine SPF-Informationen",
                            "yes": "SPF übereinstimmend"
                        },
                        "header": "SPF-Ergebnisse",
                        "no_details": "Keine SPF-Details",
                        "pass_tooltips": {
                            "error": "Temporärer SPF-Fehler",
                            "no": "SPF fehlgeschlagen",
                            "none": "SPF keiner",
                            "yes": "SPF bestanden"
                        }
                    }
                },
                "volume": {
                    "header": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "erstellt",
                "deleted": "gelöscht",
                "restored": "wiederhergestellt",
                "updated": "aktualisiert"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Zertifikat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "nameservers": "Nameserver",
                        "selector": "Selektor"
                    },
                    "title": "BIMI-Einstellungen :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Beschreibung",
                        "record_type": "Eintragstyp",
                        "selector": "Selektor",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Wert"
                    },
                    "title": "Öffentlicher DKIM-Schlüssel :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegierung aktiviert",
                        "is_policy_enabled": "Richtlinie aktiviert",
                        "nameservers": "Nameserver",
                        "policy": "Richtlinie"
                    },
                    "title": "DKIM-Einstellungen :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM-Abgleich",
                        "policy": "Richtlinie",
                        "policy_percentage": "Prozentsatz der Richtlinie",
                        "rua_emails": "Aggregierte Berichtsempfänger",
                        "rua_interval": "Aggregiertes Berichtsintervall",
                        "ruf_emails": "Empfänger von Fehlerberichten",
                        "ruf_format": "Format des Fehlerberichts",
                        "ruf_options": "Optionen für Fehlerberichte",
                        "ruf_status": "Status des Fehlerberichts",
                        "spf_alignment": "SPF-Abgleich",
                        "subdomain_policy": "Subdomain-Richtlinie"
                    },
                    "title": "DMARC-Einstellungen :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Nachrichten",
                        "result": "Ergebnis",
                        "started_at": "Begonnen am",
                        "status": "Status",
                        "type": "Typ"
                    },
                    "title": "DNS-Delegierungsverifizierung :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Favorit",
                        "logo": "Logo",
                        "score": "Domain-Score",
                        "type": "Typ"
                    },
                    "title": "Domain :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Eintrag-Hosting aktiviert",
                        "mx_records": "MX-Einträge",
                        "policy": "Richtlinieneinstellung"
                    },
                    "title": "MTA-STS-Einstellungen :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Beschreibung",
                        "mechanism": "Eintragstyp",
                        "order": "Reihenfolge",
                        "qualifier": "Qualifikator des Eintrags",
                        "status": "Status",
                        "value": "Hostwert"
                    },
                    "title": "SPF-Anweisungen :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Qualifikator des Eintrags der Top-Level-Domain",
                        "enable_flattening": "SPF-Abflachung aktiviert"
                    },
                    "title": "SPF-Einstellungen :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT aktiviert",
                        "rua_emails": "E-Mails melden"
                    },
                    "title": "TLS-RPT-Einstellungen :event"
                }
            },
            "header": ":type Protokolle"
        },
        "menu": {
            "account": "Konto",
            "domains": "Domains",
            "home": "Dashboard",
            "reports": {
                "email_senders": "E-Mail-Absender",
                "failure_reports": "Fehlerberichte",
                "reports": "Berichte",
                "tls_rpt_reports": "TLS-RPT-Berichte"
            },
            "settings": {
                "alerts": "Benachrichtigungen",
                "logout": "Abmelden",
                "settings": "Einstellungen"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "MTA-STS-Hosting deaktiviert",
                "heading": "MTA-STS-Hosting ist aktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung und das MTA-STS-Richtlinienhosting zu aktivieren.",
                "modal": {
                    "header": "Deaktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie MTA-STS-Hosting",
                "heading": "MTA-STS-Hosting ist deaktiviert",
                "message": "Es wird empfohlen, MTA-STS Record and Policy Hosting zu aktivieren, um Ihre Datensätze effektiv verwalten zu können.",
                "modal": {
                    "header": "Aktivieren Sie MTA-STS-Hosting für :domain",
                    "message": "Durch die Aktivierung von MTA-STS-Hosting können Sie Ihren Eintrag und das Hosting für Ihre Richtlinie verwalten."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX-Server"
                },
                "policy": {
                    "input_label": "Höhe der Richtliniendurchsetzung",
                    "options": {
                        "enforce": "Erzwungen",
                        "none": "Keine",
                        "testing": "Zum Testen"
                    }
                }
            },
            "heading": "MTA-STS-Eintrag und Richtlinien-Hosting",
            "info": {
                "heading": "Was ist TLS-RPT und MTA-STS?",
                "message": "TLS-RPT oder SMTP TLS Reporting (Transport Layer Security Reporting) ermöglicht es sendenden Systemen, Statistiken und Informationen über Fehler mit Empfängerdomains auszutauschen. MTA-STS (Mail Transfer Agents - Strict Transport Security) erlaubt es E-Mail-Dienstanbietern, festzulegen, dass eingehende SMTP-Verbindungen nur über TLS gesichert akzeptiert werden."
            },
            "status": {
                "policy": {
                    "pending": "Richtlinienüberprüfung ausstehend",
                    "verified": "Richtlinie bestätigt"
                },
                "record": {
                    "pending": "Eintragsüberprüfung ausstehend",
                    "verified": "Eintrag bestätigt"
                }
            }
        },
        "partners": {
            "title": "Partner verwalten"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "DMARC-Einstellungen konfigurieren",
                        "manage_domains": "Domains verwalten"
                    },
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis Daten angezeigt werden. Berichte können nur für aktive und verifizierte Domains abgerufen werden. Verwalten Sie Ihre Domains im Modul Domains.",
                    "header": "Keine Berichtsdaten verfügbar"
                },
                "no_reporting_data": {
                    "description": "Für den ausgewählten Zeitraum sind keine Berichtsdaten verfügbar. Bitte passen Sie Ihre Auswahl an und versuchen Sie es erneut.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            },
            "senders": {
                "unknown": "Unbekannt"
            }
        },
        "scoring": {
            "blurb": {
                "high": "Sie haben keine wirksamen Maßnahmen zum Schutz Ihrer Domain vor Nachahmung und Abfangen der E-Mail-Kommunikation implementiert. Dies setzt Ihr Unternehmen und die E-Mail-Empfänger einem Risiko von Angriffen aus, verringert das Vertrauen und kann die Zustellbarkeit von E-Mails beeinträchtigen.",
                "low": "E-Mails von Ihrer Domain sind sehr gut vor Nachahmung und Abfangen geschützt",
                "moderate": "Sie haben einige Maßnahmen implementiert, um Empfänger vor bösartigen E-Mails von Ihrer Domain zu schützen. Es sollte jedoch darauf geachtet werden, Bereiche zu verbessern, in denen die Kontrollen verstärkt werden können. Diese Maßnahmen helfen, Vertrauen in Ihr Unternehmen aufzubauen, schützen die Empfänger vor Angriffen und sorgen für eine effektive Zustellung von E-Mails."
            },
            "header": "Domain-Bewertung",
            "impersonation": "Identitätsbetrug",
            "marketing": "Marketing",
            "note": "HINWEIS: Dies ist Ihre aktuelle Domain-Bewertung und spiegelt möglicherweise nicht die Änderungen wider, die Sie heute vorgenommen haben.",
            "privacy": "Datenschutz",
            "rating": {
                "high": "Hohes Risiko",
                "low": "Geringes Risiko",
                "moderate": "Mäßiges Risiko"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Anzahl blockiert",
                "compliance": "Compliance",
                "deliverability": "Zustellbarkeit",
                "deliverable": "Zustellbar",
                "deliverable_volume": "Anzahl zugestellt",
                "ip_addresses": "IP-Adressen",
                "senders": "Absender",
                "volume": "E-Mail-Anzahl"
            },
            "categories": {
                "all": "Alle Absender",
                "authorised_compliant": "Genehmigt",
                "forwarder": "Weiterleiter",
                "suspicious": "Verdächtig",
                "unauthorised": "Nicht genehmigt",
                "unknown": "Keine"
            },
            "menu": {
                "compliance": "Compliance",
                "deliverability": "Zustellbarkeit",
                "reputation": "Reputation",
                "threats": "Bedrohungen"
            },
            "messages": {
                "category_updated": {
                    "error": "Die Absenderkategorie konnte nicht aktualisiert werden",
                    "success": "Absenderkategorie aktualisiert"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Auf der Blacklist",
                    "clean": "Gültig",
                    "compliant": "Konform",
                    "delivered": "Zugestellt",
                    "failing_dmarc": "DMARC-prüfung fehlgeschlagen",
                    "forward": "Weiterleitungen",
                    "not_delivered": "Nicht zugestellt",
                    "overridden": "Überschrieben",
                    "overridden_as_delivered": "Als zugestellt überschrieben",
                    "overridden_as_not_delivered": "Als nicht zugestellt überschrieben",
                    "passing": "Bestanden",
                    "passing_dkim": "Nur DKIM bestanden",
                    "passing_dkim_spf": "DKIM und SPF bestanden",
                    "passing_dmarc": "DMARC bestanden",
                    "passing_spf": "Nur SPF bestanden",
                    "senders": "Absender"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Genehmigte Absender-IPs",
                        "approved_sender_volume": "Genehmigtes Absendervolumen",
                        "blacklisted": "Auf der Blacklist",
                        "blacklists": "Blacklists",
                        "blocked_volume": "Anzahl blockiert",
                        "category": "Kategorie",
                        "clean": "Gültig",
                        "compliance": "Compliance",
                        "country": "Land",
                        "deliverability": "Zuzstellbarkeit",
                        "delivered": "Zugestellt",
                        "failing": "Fehlgeschlagen",
                        "forwards": "Weiterleitungen",
                        "host": "Host",
                        "ip": "IP",
                        "ip_addresses": "IP-Adressen",
                        "not_delivered": "Nicht zugestellt",
                        "passing": "Bestanden",
                        "sender": "Absender",
                        "top_blacklisted_senders": "Top-Absender auf der Blacklist",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Anzahl der Blacklists, in denen die IP-Adresse aufgeführt ist"
                    }
                }
            },
            "title": "E-Mail-Absender für :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Neue SPF-Anweisung hinzufügen",
                "edit_record": {
                    "tooltip": "Datensatz bearbeiten"
                }
            },
            "create_drawer": {
                "header": "Neue SPF-Anweisung hinzufügen"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Anweisungen",
                        "order": "Reihenfolge"
                    }
                },
                "titles": {
                    "allow_a_none": "A-Einträge für Ihre eigene Domain erlauben",
                    "allow_a_value": "A-Einträge erlauben für :value",
                    "allow_exists": "Das Existenz-Makro :value erlauben",
                    "allow_include": ":value einschließen",
                    "allow_ip4_address": "Die IPv4-Adresse :value einschließen",
                    "allow_ip4_addresses": "Die IPv4-Adressen in :value einschließen",
                    "allow_ip6_address": "Die IPv6-Adresse :value einschließen",
                    "allow_ip6_addresses": "Die IPv6-Adressen in :value einschließen",
                    "allow_mx_none": "MX-Einträge für Ihre eigene Domain erlauben",
                    "allow_mx_value": "MX-Einträge erlauben für :value",
                    "fail_a_none": "Fehler bei A-Einträgen für Ihre eigene Domain",
                    "fail_a_value": "Fehler bei A-Einträgen für :value",
                    "fail_exists": "Fehler im Existenz-Makro :value",
                    "fail_include": "Fehler beim Einschließen von :value",
                    "fail_ip4_address": "Fehler bei der IPv4-Adresse :value",
                    "fail_ip4_addresses": "Fehler bei den IPv4-Adressen in :value",
                    "fail_ip6_address": "Fehler bei der IPv6-Adresse :value",
                    "fail_ip6_addresses": "Fehler bei den IPv6-Adressen in :value",
                    "fail_mx_none": "Fehler bei MX-Einträgen für Ihre eigene Domain",
                    "fail_mx_value": "Fehler bei MX-Einträgen für :value",
                    "neutral_a_none": "Neutral bei A-Einträgen für Ihre eigene Domain",
                    "neutral_a_value": "Neutral bei A-Einträgen für :value",
                    "neutral_exists": "Neutral im Existenz-Makro :value",
                    "neutral_include": "Neutral beim Einschließen von :value",
                    "neutral_ip4_address": "Neutral bei der IPv4-Adresse :value",
                    "neutral_ip4_addresses": "Neutral bei den IPv4-Adressen in :value",
                    "neutral_ip6_address": "Neutral bei der IPv6-Adresse :value",
                    "neutral_ip6_addresses": "Neutral bei den IPv6-Adressen in :value",
                    "neutral_mx_none": "Neutral bei MX-Einträgen für Ihre eigene Domain",
                    "neutral_mx_value": "Neutral bei MX-Einträgen für :value",
                    "soft_fail_a_none": "Leichter Fehler bei A-Einträgen für Ihre eigene Domain",
                    "soft_fail_a_value": "Leichter Fehler bei A-Einträgen für :value",
                    "soft_fail_exists": "Leichter Fehler im Existenz-Makro :value",
                    "soft_fail_include": "Leichter Fehler beim Einschließen von :value",
                    "soft_fail_ip4_address": "Leichter Fehler bei der IPv4-Adresse :value",
                    "soft_fail_ip4_addresses": "Leichter Fehler bei den IPv4-Adressen in :value",
                    "soft_fail_ip6_address": "Leichter Fehler bei der IPv6-Adresse :value",
                    "soft_fail_ip6_addresses": "Leichter Fehler bei den IPv6-Adressen in :value",
                    "soft_fail_mx_none": "Leichter Fehler bei MX-Einträgen für Ihre eigene Domain",
                    "soft_fail_mx_value": "Leichter Fehler bei MX-Einträgen für :value"
                }
            },
            "edit_drawer": {
                "header": "SPF-Anweisung bearbeiten"
            },
            "fields": {
                "description": {
                    "input_label": "Beschreibung"
                },
                "host": {
                    "example": "z.B. _spf.example.com",
                    "input_label": ":type Wert"
                },
                "mechanism": {
                    "input_label": "Eintragstyp"
                },
                "qualifier": {
                    "input_label": "Qualifikator des Eintrags",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (nicht empfohlen)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "SPF-Anweisung aktualisieren",
                "use_own_domain": {
                    "input_label": "Mechanismus-Domain"
                }
            },
            "import_modal": {
                "action": "Entfernen und importieren",
                "complete": {
                    "message": "SPF-Import abgeschlossen"
                },
                "header": "Vorhandene SPF-Einträge und -Anweisungen für :domain importieren",
                "message": "Alle vorhandenen Anweisungen werden entfernt und durch importierte Anweisungen ersetzt."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Qualifikator des Eintrags der Top-Level-Domain",
                        "options": {
                            "allow": "+ Pass (nicht empfohlen)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (nicht empfohlen)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+all ist so eingestellt, dass es die Authentifizierung von E-Mails von Absendern besteht, die nicht autorisiert sind.",
                            "-": "Fail ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern fehlschlägt.",
                            "?": "Neutral ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern weder bestanden noch fehlgeschlagen wird.",
                            "~": "Soft Fail ist so eingestellt, dass die Authentifizierung von E-Mails von nicht autorisierten Absendern scheitert und sie als verdächtig behandelt werden."
                        }
                    },
                    "flattening": {
                        "input_label": "SPF-Abflachung aktivieren"
                    }
                },
                "heading": "Einstellungen"
            },
            "title": "Einstellungen bearbeiten – SPF"
        },
        "tables": {
            "clear": "Leeren",
            "not_found": "Keine passenden Einträge gefunden",
            "search": "Suchen"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "TLS-RPT-Einstellungen konfigurieren",
                    "description": "Wir haben noch keine Berichtsdaten für Ihre Domain erhalten. Es kann bis zu 72 Stunden dauern, bis die Daten erscheinen. TLS-RPT-Berichte können nur für Domains abgerufen werden, bei denen TLS-RPT-Berichterstattung aktiviert ist.",
                    "header": "Keine Berichtsdaten verfügbar"
                }
            },
            "heading": "TLS-RPT-Berichte für :domain",
            "reporters": {
                "heading": "Berichte",
                "legend": {
                    "reports": "Berichte"
                },
                "table": {
                    "header": {
                        "policy_mode": "Richtlinieneinstellung",
                        "policy_type": "Richtlinientyp",
                        "reporter": "Berichtersteller",
                        "total": "Gesamt"
                    }
                }
            },
            "timeline": {
                "heading": "Zeitübersicht",
                "legend": {
                    "failure": "Fehlgeschlagen",
                    "success": "Erfolgreich"
                }
            },
            "title": "TLS-RPT-Berichte"
        },
        "tls_settings": {
            "disable": {
                "action": "TLS-RPT-Berichterstellung deaktivieren",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "TLS-RPT-Berichterstellung für :domain deaktiviert",
                    "message": "Alle Einstellungen und Konfigurationen für diese Domain werden entfernt. Diese Aktion kann nicht rückgängig gemacht werden."
                }
            },
            "enable": {
                "action": "Aktivieren Sie die TLS-RPT-Berichterstellung",
                "heading": "Die TLS-RPT-Berichterstellung ist deaktiviert",
                "message": "Es wird empfohlen, die TLS-RPT-Berichterstellung zu aktivieren, um die Berichterstellung zu überwachen.",
                "modal": {
                    "header": "Aktivieren Sie die TLS-RPT-Berichterstellung für :domain",
                    "message": "Durch das Aktivieren der TLS-RPT-Berichterstattung können Sie Statistiken und Informationen über Fehler mit Empfängerdomains überwachen."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Berichte werden gesendet an"
                }
            },
            "heading": "TLS-RPT-Berichterstellung",
            "title": "Einstellungen bearbeiten – TLS-RPT"
        },
        "upload": {
            "add": "Dateien hinzufügen",
            "drop_zone": "Ziehen Sie die Datei hierher, um sie hochzuladen.",
            "title": "Hochladen"
        },
        "users": {
            "email": {
                "edit": "E-Mail bearbeiten",
                "placeholder": "Geben Sie eine E-Mail-Adresse ein"
            },
            "menu": "Benutzer",
            "name": {
                "title": "Name"
            },
            "title": "Benutzer verwalten"
        },
        "validation": {
            "email": "Keine gültige E-Mail-Adresse",
            "invalid_attr": ":attribute ist ungültig."
        }
    },
    "en": {
        "alerts": {
            "actions": {
                "create_activate": "Create & Activate",
                "edit_alert": {
                    "tooltip": "Edit Alert"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Mail Senders",
                    "none": "None",
                    "reports": "Reports",
                    "status": "Status"
                },
                "description": "Select the activity you would like to monitor.",
                "heading": "Activity",
                "options": {
                    "customer-created": {
                        "description": "Notify me when an account is created.",
                        "heading": "Customer Created"
                    },
                    "customer-deleted": {
                        "description": "Notify me when an account is deleted.",
                        "heading": "Customer Deleted"
                    },
                    "customer-exposure-report": {
                        "description": "Schedule when you would like to receive the Exposure Report.",
                        "heading": "Exposure Report"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Schedule DMARC Compliance Certificate when DMARC Policy is set to Reject.",
                        "heading": "DMARC Compliance Certificate On Reject"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notify me when DNS delegation re-verification fails.",
                        "heading": "Setup Re-Verification Failed"
                    },
                    "dns-delegation-verified": {
                        "description": "Notify where the verification status has changed",
                        "heading": "Verification Status Changed"
                    },
                    "domain-compliance-drop": {
                        "description": "Notify me when compliance drops below a threshold.",
                        "heading": "Compliance Dropped"
                    },
                    "domain-created": {
                        "description": "Notify me when a new domain is added.",
                        "heading": "Domain Created"
                    },
                    "domain-deleted": {
                        "description": "Notify me when a domain is deleted.",
                        "heading": "Domain Deleted"
                    },
                    "domain-score-change": {
                        "description": "Notify me when the DMARC Compliance Score has changed.",
                        "heading": "DMARC Compliance Score Changed"
                    },
                    "domain-settings-change": {
                        "description": "Notify me when settings are changed.",
                        "heading": "Changes to Settings"
                    },
                    "sender-threat-level-change": {
                        "description": "Notify me when Threat Level increase for new or existing senders.",
                        "heading": "Threat Level Increased"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notify me when a new, commonly approved sender is detected.",
                        "heading": "Approved Sender Found"
                    }
                }
            },
            "create": {
                "title": "Create Alert"
            },
            "edit": {
                "title": "Edit Alert"
            },
            "email": {
                "description": "Specify email recipient you would like to notify and customize the email.",
                "heading": "Email"
            },
            "email_settings": {
                "heading": "Customize Email"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Customer",
                        "domain": "Domain"
                    }
                },
                "frequency": {
                    "input_label": "Frequency"
                },
                "recipients": {
                    "input_label": "Recipients"
                },
                "scope": {
                    "input_label": "Level of Monitoring",
                    "listing-title": ":scope Activity",
                    "options": {
                        "customer": "Customer",
                        "partner": "Partner",
                        "system": "System"
                    }
                },
                "select_below": {
                    "description": "Setup Verification",
                    "input_label": "Select Below"
                },
                "start_date": {
                    "input_label": "Start Date"
                },
                "status": {
                    "input_label": "Status"
                },
                "subject_tags": {
                    "example": "e.g. [alert-severity][account][package][domain]",
                    "input_label": "Enable subject line categorization prefixes"
                }
            },
            "frequency": {
                "description": "Choose how often you would like to receive this notification.",
                "disabled-text": "Not Available - This is an event-based activity",
                "heading": "Frequency",
                "options": {
                    "monthly": "Monthly",
                    "quarterly": "Quarterly",
                    "six_monthly": "Six Monthly",
                    "two_monthly": "Two Monthly",
                    "yearly": "Yearly"
                },
                "starting": "Starting"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alert",
                        "frequency": "Frequency",
                        "recipients": "Recipients",
                        "status": "Status"
                    },
                    "record": {
                        "alert_activity": ":scope Activity"
                    }
                },
                "title": "Alerts"
            },
            "monitor": {
                "description": "Select the activity you would like to monitor.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Daily",
                        "fortnightly": "Fortnightly",
                        "hourly": "Hourly",
                        "immediate": "Immediate",
                        "monthly": "Monthly",
                        "quarterly": "Quarterly",
                        "six_monthly": "Six monthly",
                        "two_monthly": "Two monthly",
                        "weekly": "Weekly",
                        "yearly": "Yearly"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "Alert is inactive",
                        "value": "off"
                    },
                    "on": {
                        "tooltip": "Alert is active",
                        "value": "on"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Disable BIMI Record Hosting",
                "heading": "BIMI Record Hosting is currently enabled",
                "message": "It is recommended that BIMI Hosting is enabled to easily manage your logo and certificate.",
                "modal": {
                    "header": "Disable BIMI Record Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable BIMI Record Hosting",
                "heading": "BIMI Hosting is Disabled",
                "message": "It is recommended that BIMI Hosting is enabled to manage your Logo and Certificate.",
                "modal": {
                    "header": "Enable BIMI Record Hosting :domain",
                    "message": "By enabling BIMI Hosting you can easily manage your logo and certificate."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificate CN",
                    "expiry": "Certificate Expiry",
                    "input_label": "BIMI Verified Mark Certificate (File type: pem)",
                    "url": "Authority \/ Certificate URL"
                },
                "image": {
                    "input_label": "BIMI Logo (File type: svg)",
                    "preview": "Logo Preview",
                    "size": "Logo size",
                    "url": "Logo Url"
                }
            },
            "info": {
                "heading": "What is BIMI?",
                "message": "BIMI (Brand Indicators for Message Identification) is a standard that makes it easier to get your logo displayed next to your messages in the inbox, prevent fraudulent emails, and aide deliverability. BIMI works alongside DMARC, DKIM, and SPF to verify an email's authenticity and help with brand visibility."
            },
            "settings": {
                "heading": "Settings"
            },
            "title": "Edit Settings - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Back to Settings",
                "share": "Share Setup Instructions",
                "verify": "Verify",
                "view": "View Setup Instructions"
            },
            "bimi": {
                "description": "Update your DNS to allow the configuration of your domain’s BIMI Public Keys.",
                "modal": {
                    "not_configured": {
                        "header": "Setup Instruction Not Available",
                        "message": "In order to generate DNS records for your BIMI configuration you’ll need to upload a logo."
                    }
                }
            },
            "description": "To enable monitoring and management through DMARC Manager please implement the following changes in your domain’s DNS settings.",
            "dkim": {
                "description": "Add the entries below to your domain`s DNS records to allow the configuration of its DKIM public keys.",
                "no_nameservers": "The DKIM public key zone is being created, please wait for the setup instructions..."
            },
            "dmarc": {
                "description": "Update your DNS to allow the configuration of your domain’s DMARC policy and to capture reports on email authentication activity through our dashboard.",
                "warning": "Take note of the required underscores (_) in the host and full-stops in the record value."
            },
            "existing_dns_record_found": "Existing :record Record Found",
            "heading": ":title Setup Instructions",
            "instructions": {
                "add": "Add :rdType Record",
                "heading": ":rdType Setup Instructions",
                "new": "Step 2: Add New :rdType Record",
                "remove": "Step 1: Remove Existing :rdType Record"
            },
            "mta_policy": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Policy.",
                "heading": "MTA-STS Policy"
            },
            "mta_record": {
                "description": "Update your DNS to allow the configuration of your domain’s MTA Record.",
                "heading": "MTA-STS Record"
            },
            "record": {
                "host": "Host",
                "type": "Record Type",
                "value": "Value"
            },
            "spf": {
                "description": "Update your DNS to allow for the configuration of your domain’s SPF record through our platform. Our platform ensures the validity of the record and makes use of optimization technology which mitigates the risk of reaching DNS lookup limits as well as ensuring that your policy is not overly permissive."
            },
            "status": {
                "last_date": "Last Verified on",
                "pending": "Pending Verification",
                "verified": "Verified"
            },
            "tls_rpt": {
                "description": "Update your DNS to allow the configuration of your domain’s TLS-RPT record and to capture reports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Manage customers"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Email volume",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved & other"
                    }
                },
                "domain_totals": {
                    "action": "View domains",
                    "enforced": "Enforced",
                    "heading": "Domains",
                    "reporting": "Reporting",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "View Senders",
                    "heading": "Senders",
                    "legends": {
                        "approved": "Approved",
                        "unapproved": "Unapproved"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "View All Domains"
                },
                "legends": {
                    "failing": "Failing DMARC",
                    "forwards": "Forwards",
                    "passing": "Passing DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "View Report"
                    },
                    "header": {
                        "approved": "Approved",
                        "domain": "Domains",
                        "senders": "Senders",
                        "unapproved": "Unapproved",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "View Domain Report"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "domain": "Domain",
                        "sender": "Approved Senders by Volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Dashboard"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "No matching name servers could be found.",
                        "mismatch": "The nameserver records do not match the expected configuration.",
                        "missing": "At least two nameserver records need to be present."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "The DKIM alignment mode does not match the configuration.",
                        "missing": "The DKIM alignment mode is missing but is not configured to the default."
                    },
                    "aspf": {
                        "mismatch": "The SPF alignment mode does not match the configuration.",
                        "missing": "The SPF alignment mode is missing but is not configured to the default."
                    },
                    "cname": {
                        "mismatch": "The CNAME record has not been added correctly and the record doesn't match the expected configuration.",
                        "partial": "The CNAME record has not been added correctly. This can cause configurations to conflict."
                    },
                    "fo": {
                        "mismatch": "The failure reporting options do not match the configuration.",
                        "missing": "The failure reporting options are missing but are not configured to the default."
                    },
                    "p": {
                        "mismatch": "The policy does not match the configuration.",
                        "missing": "The policy is missing."
                    },
                    "pct": {
                        "mismatch": "The policy percentage does not match the configuration.",
                        "missing": "The policy percentage is missing but is not configured to the default."
                    },
                    "rf": {
                        "mismatch": "The failure reporting format does not match the configuration."
                    },
                    "ri": {
                        "mismatch": "The aggregate report interval does not match the configuration.",
                        "missing": "The aggregate report interval is missing but is not configured to the default."
                    },
                    "rua": {
                        "mismatch": "The aggregate report recipients do not match the configuration.",
                        "order": "The aggregate report recipients are not in the correct order."
                    },
                    "ruf": {
                        "mismatch": "The failure report recipients do not match the configuration.",
                        "order": "The failure report recipients are not in the correct order."
                    },
                    "sp": {
                        "mismatch": "The subdomain policy does not match the configuration.",
                        "missing": "The subdomain policy is missing but is configured differently to the domain policy."
                    },
                    "txt": {
                        "reporting-only": "The record doesn't match the expected configuration, but our reporting address is present."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "The TXT value does not match the expected value."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "The CNAME value does not match the expected value.",
                        "missing": "No matching CNAME record could be found."
                    },
                    "rua": {
                        "missing": "The expected reporting address is missing."
                    }
                }
            },
            "verify": {
                "error": "Verification Failed",
                "nxdomain": "Record Not Found.",
                "success": "Verification Completed Successfully",
                "timeout": "Verification Timeout"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Add New Public Key",
                "edit_public_key": {
                    "tooltip": "Edit Public Key"
                }
            },
            "create_drawer": {
                "header": "Add New Public Key"
            },
            "disable": {
                "action": "Disabled DKIM Management",
                "heading": "DKIM Public Key Hosting is Currently Enabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Disable DKIM Management for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "edit_drawer": {
                "header": "Edit Public Key"
            },
            "enable": {
                "action": "Enable DKIM Management",
                "heading": "DKIM Public Key Hosting is Disabled",
                "message": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
                "modal": {
                    "header": "Enable DKIM Management for :domain",
                    "message": "By enabling DKIM management you can manage your public keys with ease."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Record Type"
                },
                "record_value": {
                    "example": "e.g. dkim.example.com",
                    "input_label": "Record Value"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "DKIM Public Key Import Completed"
                },
                "disabled_tooltip": "DKIM public keys can only be imported for domains that have reports.",
                "header": "Re-import DKIM Public Keys for :domain",
                "message": "All existing public keys will be removed and replaced with imported directives."
            },
            "info": {
                "heading": "What is DKIM?",
                "message": "Domain Keys Identified Mail is an email authentication method that allows a sender to digitally sign an email using a private key associated with their domain name, which can be verified by the recipient's mail server using a public key published in the sender's DNS records. This helps to verify the authenticity and integrity of the email, reducing the risk of spam and phishing attacks."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Outbound Signing Policy",
                        "options": {
                            "all": "All Emails",
                            "some": "Some Emails"
                        }
                    },
                    "testing": {
                        "input_label": "Testing Mode"
                    }
                },
                "heading": "Policy Settings"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "value": "Value"
                    }
                }
            },
            "title": "Edit Settings - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "DKIM Alignment mode"
                    },
                    "options": {
                        "r": "Relaxed",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "SPF Alignment mode"
                    }
                },
                "heading": "Alignment Settings"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Apply Different Subdomain Policy"
                    },
                    "percentage": {
                        "input_label": "Percentage"
                    },
                    "policy": {
                        "input_label": "DMARC Policy"
                    },
                    "subdomain_policy": {
                        "input_label": "Subdomain Policy"
                    }
                },
                "heading": "Policy Settings"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Reports Will be Sent to"
                    },
                    "interval": {
                        "input_label": "Report Interval",
                        "options": {
                            "1_day": "Once a Day",
                            "1_hour": "Every Hour",
                            "2_hours": "Every 2 Hours",
                            "4_hours": "Every 4 Hours",
                            "6_hours": "Every 6 Hours"
                        }
                    }
                },
                "heading": "Aggregate Reports"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Enable",
                    "header": "Enable failure reporting",
                    "message": "By enabling failure reporting, some personal information, such as email addresses or message content, may be exposed for diagnostic purposes",
                    "reject": "Disable"
                },
                "fields": {
                    "options": {
                        "input_label": "Report Options",
                        "options": {
                            "d": "DKIM fails regardless of alignment",
                            "s": "SPF fails regardless of alignment",
                            "0": "SPF or DKIM fail and don't align",
                            "1": "SPF and DKIM fail and don't align"
                        }
                    },
                    "status": {
                        "input_label": "Report Processing",
                        "options": {
                            "disabled": "Disabled",
                            "enabled": "Enabled",
                            "specified": "Specific Recipients"
                        }
                    }
                },
                "heading": "Failure Reports"
            },
            "title": "Edit Settings - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "DMARC Compliance Certificate",
                "domain_report": "Domain Report",
                "edit_settings": {
                    "tooltip": "Edit Settings"
                },
                "remove_primary": "Remove from Favourite",
                "set_as_active": "Set as Active",
                "set_as_parked": "Set as Parked",
                "set_primary": "Mark as Favourite",
                "view_logs": "View Logs"
            },
            "create_drawer": {
                "header": "Add New Domain"
            },
            "fields": {
                "name": {
                    "example": "e.g. 'example.com' or 'subdomain.example.com'",
                    "input_label": "Domain Name"
                },
                "type": {
                    "input_label": "Domain Type",
                    "options": {
                        "active": "Active",
                        "parked": "Parked"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domain",
                        "policy": "Policy",
                        "score": "Score",
                        "status": "Status",
                        "type": "Type"
                    }
                },
                "title": "Domains"
            },
            "logs_drawer": {
                "header": "Domain"
            },
            "report_modal": {
                "action": "Generate Link",
                "fields": {
                    "copy_link": {
                        "input_label": "Copy the link below to share domain report"
                    },
                    "date_range": {
                        "input_label": "Date Range"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Domain Report"
            },
            "settings": {
                "title": "Edit Settings"
            },
            "setup_modal": {
                "action": "Generate Link",
                "fields": {
                    "copy_link": {
                        "input_label": "Copy the link below to share setup instructions"
                    },
                    "expire_link": {
                        "input_label": "Expire Link in",
                        "options": {
                            "1_day": "One Day",
                            "1_month": "One Month",
                            "1_week": "One Week",
                            "6_hours": "6 Hours",
                            "12_hours": "12 Hours",
                            "never": "Never"
                        }
                    }
                },
                "header": "Link to Setup Instructions"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "None"
                    },
                    "quarantine": {
                        "value": "Quarantine"
                    },
                    "reject": {
                        "value": "Reject"
                    },
                    "tooltip": "Policy Applied"
                },
                "primary": {
                    "tooltip": "Favourite",
                    "value": "Favourite"
                },
                "score": {
                    "high": {
                        "tooltip": "High Risk"
                    },
                    "low": {
                        "tooltip": "Low Risk"
                    },
                    "moderate": {
                        "tooltip": "Moderate Risk"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verified, Not Reporting",
                        "tooltip_partial": "Partially Verified, Not Reporting",
                        "value": "Not Reporting"
                    },
                    "not_verified": {
                        "tooltip": "Not Verified, Not Reporting",
                        "value": "Not Verified"
                    },
                    "reporting": {
                        "tooltip": "Verified, Reporting",
                        "tooltip_partial": "Partially Verified, Reporting",
                        "value": "Reporting"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Active Domain",
                        "value": "Active"
                    },
                    "parked": {
                        "tooltip": "Parked Domain",
                        "value": "Parked"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "e.g. recipient@example.com",
                    "input_label": "Edit Email",
                    "placeholder": "Enter Email Address",
                    "subscribed_label": "Subscribed",
                    "unsubscribed_label": "Unsubscribed",
                    "validation_message": "Enter Valid Email Address"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Sorry, you are unauthorized to access this page.",
                "title": "401: Unauthorized"
            },
            "403": {
                "description": "Sorry, you are forbidden from accessing this page.",
                "title": "403: Forbidden"
            },
            "404": {
                "description": "Sorry, the page you are looking for could not be found.",
                "title": "404: Page Not Found"
            },
            "500": {
                "description": "Whoops, something went wrong on our servers.",
                "title": "500: Server Error"
            },
            "502": {
                "description": "Sorry, our servers are down.",
                "title": "502: Bad Gateway"
            },
            "503": {
                "description": "Sorry, we are doing some maintenance. Please check back soon.",
                "title": "503: Service Unavailable"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Email Volume",
                "legends": {
                    "failing": "Failing DMARC",
                    "forward": "Forwards",
                    "passing": "Passing DMARC"
                }
            },
            "heading": "Domain Report",
            "recommendations": {
                "heading": "Recommendations"
            },
            "risk_analysis": {
                "heading": "Risk Analysis"
            },
            "scoring": {
                "note": "Note: The domain score is calculated at the time of generating the report and may not be up to date at the time of viewing."
            },
            "sender_readiness": {
                "heading": "Sender DMARC Readiness",
                "legends": {
                    "configured": {
                        "description": "DMARC Readiness is a measure of the configuration require to implement full compliance.",
                        "heading": "Configured"
                    },
                    "partially": {
                        "description": "Partially Configured Sources have at least one authorisation mechanism active however, additional setup may be necessary.",
                        "heading": "Partially Configured"
                    },
                    "unconfigured": {
                        "description": "Illegitimate or Unconfigured Sources, are sources sending email from your domain that may be impersonating your brand.",
                        "heading": "Illegitimate or Unconfigured"
                    }
                }
            },
            "title": "Domain report for :domain",
            "top_countries": {
                "heading": "Top Origination Countries - Email Volume"
            },
            "top_senders": {
                "heading": "Top Senders - Compliance"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Download Report",
                "header": "Failure Report",
                "menu": {
                    "key_properties": "Key Properties",
                    "more_info": "More Info"
                }
            },
            "properties": {
                "authentication_failure": "Authentication Failure",
                "date": "Date",
                "delivery_results": "Delivery Results",
                "dkim_domain": "DKIM Domain",
                "dkim_header": "DKIM Canonicalised Header",
                "dkim_identity": "DKIM Identity",
                "dkim_selector": "DKIM Selector",
                "dmarc_version": "DMARC Version",
                "feedback_type": "Feedback Type",
                "incidents": "Number of Incidents",
                "original_recipients": "Original Recipients",
                "reporting_domains": "Reported Domains",
                "reporting_mta": "Reporting MTA",
                "reporting_user_agent": "Reporting User Agent",
                "return_path": "Return Path",
                "source_ip": "Source IP Address",
                "spf_dns": "SPF DNS"
            },
            "table": {
                "header": {
                    "authentication": "Authentication",
                    "date": "Date",
                    "ip_address": "IP Address",
                    "result": "Result",
                    "return_path": "Return Path"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Delivered",
                    "none": "None, Accept",
                    "smg_policy_action": "Policy Applied",
                    "spam": "Rejected",
                    "unknown": "Unknown"
                }
            },
            "title": "Failure Reports for :domain"
        },
        "global": {
            "buttons": {
                "add": "Add",
                "add_new": "Add New",
                "back": "Back",
                "cancel": "Cancel",
                "delete": "Delete",
                "import": "Import",
                "next": "Next",
                "no": "No",
                "open_link": "Open Link",
                "remove": "Remove",
                "save": "Save",
                "save_exit": "Save and Back",
                "select": "Select",
                "yes": "Yes"
            },
            "confirm_delete": "Do you want to continue?",
            "copied": "Copied",
            "created": "Created",
            "deleted": "Deleted",
            "fail": "Fail",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Disabled",
                        "enabled": "Enabled"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Off",
                        "on": "On"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Yes"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Error: Please fill out all required fields correctly.",
                    "title": "There were errors with your form."
                },
                "success": {
                    "title": "Your changes were saved."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Logout",
            "not_found": "Not found",
            "pagination": {
                "of": "of",
                "showing": "Showing",
                "to": "to"
            },
            "pass": "Pass",
            "remove": "Remove",
            "show_less": "Show less",
            "show_more": "Show more",
            "system": "System",
            "theme": {
                "dark": "Dark",
                "light": "Light"
            },
            "to": "To"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Date range",
                "to": "to"
            },
            "menu": {
                "overrides": "Overrides",
                "overview": "Overview",
                "reporters": "Reporters",
                "results": "Results"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Policy applied 'None'",
                    "policy_applied_quarantine": "Policy applied 'Quarantine'",
                    "policy_applied_reject": "Policy applied 'Reject'"
                },
                "overridden_policy_breakdown": "Overridden policy Breakdown",
                "volume_overridden_policy": "Volume with overridden policies"
            },
            "overview": {
                "cards": {
                    "compliance": "Compliance",
                    "deliverability": "Deliverability",
                    "volume": "Volume"
                },
                "properties": {
                    "country": "Country",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organisation",
                    "sender": "Sender"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Compliance",
                        "envelope_from": "Envelope From",
                        "envelope_to": "Envelope To",
                        "header_from": "Header From",
                        "no_data": "No reporter data available",
                        "percent_of_total": "% of Total",
                        "reporter": "Reporter",
                        "volume": "Volume"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alignment",
                    "error": "Error",
                    "fail": "Fail",
                    "failed": "Failed\/Not Aligned",
                    "none": "None",
                    "pass": "Pass",
                    "passed": "Passed\/Aligned"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Action Applied",
                    "info": {
                        "tooltip": "Action Taken by Receiver"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Delivered",
                            "value": "Delivered"
                        },
                        "quarantine": {
                            "tooltip": "Quarantined",
                            "value": "Quarantined"
                        },
                        "reject": {
                            "tooltip": "Rejected",
                            "value": "Rejected"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_dkim": "DMARC via DKIM",
                            "results": "Results",
                            "selector": "Selector",
                            "signing_domain": "Signing Domain"
                        },
                        "heading": "DKIM Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM Not Aligned",
                            "no_info": "No DKIM Info",
                            "yes": "DKIM Aligned"
                        },
                        "header": "DKIM Results",
                        "no_details": "No DKIM Signature Details",
                        "pass_tooltips": {
                            "no": "DKIM Failed",
                            "none": "DKIM None",
                            "yes": "DKIM Pass"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "Policy of ':policy' was applied by receiver"
                        },
                        "header": {
                            "from_domain": "From Domain"
                        },
                        "heading": "Other Details"
                    },
                    "results": {
                        "header": "DMARC Results",
                        "pass_tooltips": {
                            "no": "DMARC Failed",
                            "yes": "DMARC Passed"
                        }
                    }
                },
                "override_comment": {
                    "header": "Comment"
                },
                "override_type": {
                    "header": "Override Type"
                },
                "published_policy": {
                    "header": "Published Policy",
                    "info": {
                        "tooltip": "DMARC policy at time of validation"
                    }
                },
                "reporter": {
                    "header": "Reporter"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alignment",
                            "dmarc_via_spf": "DMARC via SPF",
                            "result": "Result",
                            "return_path_domain": "Return Path Domain"
                        },
                        "heading": "SPF Details"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF Not Aligned",
                            "no_info": "No SPF Info",
                            "yes": "SPF Aligned"
                        },
                        "header": "SPF Results",
                        "no_details": "No SPF Details",
                        "pass_tooltips": {
                            "error": "SPF Temporary Error",
                            "no": "SPF Failed",
                            "none": "SPF None",
                            "yes": "SPF Pass"
                        }
                    }
                },
                "volume": {
                    "header": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "created",
                "deleted": "deleted",
                "restored": "restored",
                "updated": "updated"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificate",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegation Enabled",
                        "nameservers": "Nameservers",
                        "selector": "Selector"
                    },
                    "title": "BIMI settings :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Record Type",
                        "selector": "Selector",
                        "status": "Status",
                        "ttl": "TTL",
                        "value": "Value"
                    },
                    "title": "DKIM public key :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegation Enabled",
                        "is_policy_enabled": "Policy Enabled",
                        "nameservers": "Nameservers",
                        "policy": "Policy"
                    },
                    "title": "DKIM settings :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "DKIM Alignment",
                        "policy": "Policy",
                        "policy_percentage": "Policy Percentage",
                        "rua_emails": "Aggregate Report Recipients",
                        "rua_interval": "Aggregate Report Interval",
                        "ruf_emails": "Failure Report Recipients",
                        "ruf_format": "Failure Report Format",
                        "ruf_options": "Failure Report Options",
                        "ruf_status": "Failure Report Status",
                        "spf_alignment": "SPF Alignment",
                        "subdomain_policy": "Subdomain Policy"
                    },
                    "title": "DMARC settings :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Result",
                        "started_at": "Started at",
                        "status": "Status",
                        "type": "Type"
                    },
                    "title": "DNS Delegation Verification :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domain",
                        "is_favorite": "Favourite",
                        "logo": "Logo",
                        "score": "Domain Score",
                        "type": "Type"
                    },
                    "title": "Domain :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Record Hosting Enabled",
                        "mx_records": "MX Records",
                        "policy": "Policy Mode"
                    },
                    "title": "MTA-STS settings :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Record Type",
                        "order": "Order",
                        "qualifier": "Record Qualifier",
                        "status": "Status",
                        "value": "Host Value"
                    },
                    "title": "SPF directives :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Top Level Record Qualifier",
                        "enable_flattening": "Flattening Enabled"
                    },
                    "title": "SPF settings :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT Enabled",
                        "rua_emails": "Report Emails"
                    },
                    "title": "TLS-RPT settings :event"
                }
            },
            "header": ":type Logs"
        },
        "menu": {
            "account": "Account",
            "domains": "Domains",
            "home": "Dashboard",
            "reports": {
                "email_senders": "Email Senders",
                "failure_reports": "Failure Reports",
                "reports": "Reports",
                "tls_rpt_reports": "TLS-RPT Reports"
            },
            "settings": {
                "alerts": "Alerts",
                "logout": "Logout",
                "settings": "Settings"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Disabled MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Enabled",
                "message": "It is recommended that TLS-RPT Reporting and MTA-STS Policy Hosting is enabled.",
                "modal": {
                    "header": "Disable MTA-STS Hosting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable MTA-STS Hosting",
                "heading": "MTA-STS Hosting is Disabled",
                "message": "It is recommended that MTA-STS Record and Policy Hosting is enabled to affective manage your records.",
                "modal": {
                    "header": "Enable MTA-STS Hosting for :domain",
                    "message": "By enabling MTA-STS Hosting you can manage your record and hosting for your policy."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "MX Servers"
                },
                "policy": {
                    "input_label": "Policy Enforcement Level",
                    "options": {
                        "enforce": "Enforced",
                        "none": "None",
                        "testing": "In Testing"
                    }
                }
            },
            "heading": "MTA-STS Record & Policy Hosting",
            "info": {
                "heading": "What is TLS-RPT and MTA-STS?",
                "message": "TLS-RPT or SMTP TLS Reporting (Transport Layer Security Reporting) enables sending systems to share statistics and information about failures with recipient domains. MTA-STS (Mail Transfer Agents - Strict Transport Security) allows email service providers to enforce that incoming SMTP connections are only accepted if they are secured via TLS."
            },
            "status": {
                "policy": {
                    "pending": "Policy Pending Verification",
                    "verified": "Policy Verified"
                },
                "record": {
                    "pending": "Record Pending Verification",
                    "verified": "Record Verified"
                }
            }
        },
        "partners": {
            "title": "Manage partners"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configure DMARC Settings",
                        "manage_domains": "Manage domains"
                    },
                    "description": "We have not yet received reporting data for your domain, it can take up to 72 hours for data to appear. Reports can only be retrieved for active and verified domains. Manage your domains in the Domains module.",
                    "header": "No Reporting Data Available"
                },
                "no_reporting_data": {
                    "description": "No reporting data available for the selected date range, please adjust your selection and try again.",
                    "header": "No Reporting Data Available"
                }
            },
            "senders": {
                "unknown": "Unknown"
            }
        },
        "scoring": {
            "blurb": {
                "high": "You do not have effective controls in place to protect your domain from impersonation and interception of email communication. This puts your brand and email recipients at risk of attacks, reduces trust, and can damage email deliverability.",
                "low": "You have some measures in place to protect recipients from malicious email from your domain. Attention should be paid to areas where controls can be improved. These measures build trust in your brand, protect recipients from attacks and help ensure email is delivered effectively.",
                "moderate": "You have implemented some measures to protect recipients from malicious emails sent from your domain. However, attention should be given to areas where controls can be strengthened. These measures help build trust in your organization, protect recipients from attacks, and ensure effective email deliverability."
            },
            "header": "Domain Score",
            "impersonation": "Impersonation",
            "marketing": "Marketing",
            "note": "NOTE: This is your current domain score and may not reflect changes you've made today.",
            "privacy": "Privacy",
            "rating": {
                "high": "High Risk",
                "low": "Low Risk",
                "moderate": "Moderate Risk"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Blocked Volume",
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "deliverable": "Deliverable",
                "deliverable_volume": "Deliverable Volume",
                "ip_addresses": "IP Addresses",
                "senders": "Senders",
                "volume": "Email Volume"
            },
            "categories": {
                "all": "All Senders",
                "authorised_compliant": "Approved",
                "forwarder": "Forwarder",
                "suspicious": "Suspicious",
                "unauthorised": "Unapproved",
                "unknown": "None"
            },
            "menu": {
                "compliance": "Compliance",
                "deliverability": "Deliverability",
                "reputation": "Reputation",
                "threats": "Threats"
            },
            "messages": {
                "category_updated": {
                    "error": "Failed to Update Sender Category",
                    "success": "Sender Category Updated"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Blacklisted",
                    "clean": "Clean",
                    "compliant": "Compliant",
                    "delivered": "Delivered",
                    "failing_dmarc": "Failing DMARC",
                    "forward": "Forwards",
                    "not_delivered": "Not Delivered",
                    "overridden": "Overridden",
                    "overridden_as_delivered": "Overridden As Delivered",
                    "overridden_as_not_delivered": "Overridden As Not Delivered",
                    "passing": "Passing",
                    "passing_dkim": "Passing DKIM Only",
                    "passing_dkim_spf": "Passing DKIM & SPF",
                    "passing_dmarc": "Passing DMARC",
                    "passing_spf": "Passing SPF Only",
                    "senders": "Senders"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Approved Sender IPs",
                        "approved_sender_volume": "Approved Sender Volume",
                        "blacklisted": "Blacklisted",
                        "blacklists": "Blacklists",
                        "blocked_volume": "Blocked Volume",
                        "category": "Category",
                        "clean": "Clean",
                        "compliance": "Compliance",
                        "country": "Country",
                        "deliverability": "Deliverability",
                        "delivered": "Delivered",
                        "failing": "Failing",
                        "forwards": "Forwards",
                        "host": "Host",
                        "ip": "IP",
                        "ip_addresses": "IP Addresses",
                        "not_delivered": "Not Delivered",
                        "passing": "Passing",
                        "sender": "Sender",
                        "top_blacklisted_senders": "Top Blacklisted Senders",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Amount of Blacklists the IP Address has been listed in"
                    }
                }
            },
            "title": "Email Senders for :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Add New SPF Directive",
                "edit_record": {
                    "tooltip": "Edit Record"
                }
            },
            "create_drawer": {
                "header": "Add New SPF Directive"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Order"
                    }
                },
                "titles": {
                    "allow_a_none": "Allow A records for your own domain",
                    "allow_a_value": "Allow A records for :value",
                    "allow_exists": "Allow the exists macro :value",
                    "allow_include": "Include :value",
                    "allow_ip4_address": "Include the IPv4 address :value",
                    "allow_ip4_addresses": "Include the IPv4 addresses in :value",
                    "allow_ip6_address": "Include the IPv6 address :value",
                    "allow_ip6_addresses": "Include the IPv6 addresses in :value",
                    "allow_mx_none": "Allow MX records for your own domain",
                    "allow_mx_value": "Allow MX records for :value",
                    "fail_a_none": "Fail A records for your own domain",
                    "fail_a_value": "Fail A records for :value",
                    "fail_exists": "Fail the exists macro :value",
                    "fail_include": "Fail to include :value",
                    "fail_ip4_address": "Fail the IPv4 address :value",
                    "fail_ip4_addresses": "Fail the IPv4 addresses in :value",
                    "fail_ip6_address": "Fail the IPv6 address :value",
                    "fail_ip6_addresses": "Fail the IPv6 addresses in :value",
                    "fail_mx_none": "Fail MX records for your own domain",
                    "fail_mx_value": "Fail MX records for :value",
                    "neutral_a_none": "Be neutral on A records for your own domain",
                    "neutral_a_value": "Be neutral on A records for :value",
                    "neutral_exists": "Be neutral on the exists macro :value",
                    "neutral_include": "Be neutral on including :value",
                    "neutral_ip4_address": "Be neutral on the IPv4 address :value",
                    "neutral_ip4_addresses": "Be neutral on the IPv4 addresses in :value",
                    "neutral_ip6_address": "Be neutral on the IPv6 address :value",
                    "neutral_ip6_addresses": "Be neutral on the IPv6 addresses in :value",
                    "neutral_mx_none": "Be neutral on MX records for your own domain",
                    "neutral_mx_value": "Be neutral on MX records for :value",
                    "soft_fail_a_none": "Soft Fail A records for your own domain",
                    "soft_fail_a_value": "Soft Fail A records for :value",
                    "soft_fail_exists": "Soft Fail the exists macro :value",
                    "soft_fail_include": "Soft Fail to include :value",
                    "soft_fail_ip4_address": "Soft Fail the IPv4 address :value",
                    "soft_fail_ip4_addresses": "Soft Fail the IPv4 addresses in :value",
                    "soft_fail_ip6_address": "Soft Fail the IPv6 address :value",
                    "soft_fail_ip6_addresses": "Soft Fail the IPv6 addresses in :value",
                    "soft_fail_mx_none": "Soft Fail MX records for your own domain",
                    "soft_fail_mx_value": "Soft Fail MX records for :value"
                }
            },
            "edit_drawer": {
                "header": "Edit SPF Directive"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "e.g. _spf.example.com",
                    "input_label": ":type Value"
                },
                "mechanism": {
                    "input_label": "Record Type"
                },
                "qualifier": {
                    "input_label": "Record Qualifier",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (Not Recommended)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Update an Spf Directive",
                "use_own_domain": {
                    "input_label": "Mechanism Domain"
                }
            },
            "import_modal": {
                "action": "Remove & Import",
                "complete": {
                    "message": "SPF Import Completed"
                },
                "header": "Import Existing SPF Record & Directives for :domain",
                "message": "All existing directives will be removed and replaced with imported directives."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Top Level Record Qualifier",
                        "options": {
                            "+": "+ Pass (Not Recommended)",
                            "-": "- Fail",
                            "?": "? Neutral (Not Recommended)",
                            "~": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+all Set to pass authentication of emails from senders that are not authorized.",
                            "-": "Set to fail authentication of emails from senders that are not authorized.",
                            "?": "Set to neutral, which neither passes nor fails authentication of emails from senders that are not authorized.",
                            "~": "Set to soft fail authentication of emails from senders that are not authorized and treat them as suspicious."
                        }
                    },
                    "flattening": {
                        "input_label": "Enable Flattening"
                    }
                },
                "heading": "Settings"
            },
            "title": "Edit Settings - SPF"
        },
        "tables": {
            "clear": "Clear",
            "not_found": "No Matching Records Found",
            "search": "Search"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configure TLS-RPT Settings",
                    "description": "We have not yet received reporting data for your domain, it can take up to 72hours for data to appear. TLS-RPT Reports can only be retrieved for domains that have TLS-RPT Reporting enabled.",
                    "header": "No Reporting Data Available"
                }
            },
            "heading": "TLS-RPT reports for :domain",
            "reporters": {
                "heading": "Reports",
                "legend": {
                    "reports": "Reports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Policy Mode",
                        "policy_type": "Policy Type",
                        "reporter": "Reporter",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Timeline",
                "legend": {
                    "failure": "Failed",
                    "success": "Successful"
                }
            },
            "title": "TLS-RPT reports"
        },
        "tls_settings": {
            "disable": {
                "action": "Disable TLS-RPT Reporting",
                "message": "It is recommended that TLS-RPT reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Disabled TLS-RPT reporting for :domain",
                    "message": "All settings and configurations for this domain will be removed, this action cannot be undone."
                }
            },
            "enable": {
                "action": "Enable TLS-RPT Reporting",
                "heading": "TLS-RPT Reporting is Disabled",
                "message": "It is recommended that TLS-RPT Reporting is enabled to monitor reporting.",
                "modal": {
                    "header": "Enable TLS-RPT Reporting for :domain",
                    "message": "By enabling TLS-RPT reporting you can monitor statistics and information about failures with recipient domains."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Reports Will be Sent to"
                }
            },
            "heading": "TLS-RPT Reporting",
            "title": "Edit Settings - TLS-RPT"
        },
        "upload": {
            "add": "Add Files",
            "drop_zone": "Drag and drop file here to upload.",
            "title": "Upload"
        },
        "users": {
            "email": {
                "edit": "Edit Email",
                "placeholder": "Enter email address"
            },
            "menu": "Users",
            "name": {
                "title": "Name"
            },
            "title": "Manage Users"
        },
        "validation": {
            "email": "Not a valid email address",
            "invalid_attr": "The :attribute is not valid."
        }
    },
    "es": {
        "alerts": {
            "actions": {
                "create_activate": "Crear y activar",
                "edit_alert": {
                    "tooltip": "Editar alerta"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuración",
                    "mail_senders": "Remitentes de correo",
                    "none": "Ninguno",
                    "reports": "Informes",
                    "status": "Estado"
                },
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Actividad",
                "options": {
                    "customer-created": {
                        "description": "Notificarme cuando se crea una cuenta.",
                        "heading": "Cliente creado"
                    },
                    "customer-deleted": {
                        "description": "Notificarme cuando se elimine una cuenta.",
                        "heading": "Cliente eliminado"
                    },
                    "customer-exposure-report": {
                        "description": "Programe cuándo le gustaría recibir el Informe de exposición.",
                        "heading": "Informe de exposición"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Programe el certificado de cumplimiento de DMARC cuando la directiva DMARC esté configurada en Rechazar.",
                        "heading": "Certificado de cumplimiento DMARC en caso de rechazo"
                    },
                    "dns-delegation-unverified": {
                        "description": "Notificarme cuando falle la nueva verificación de la delegación de DNS.",
                        "heading": "Error en la nueva verificación de configuración"
                    },
                    "dns-delegation-verified": {
                        "description": "Notificar dónde ha cambiado el estado de verificación",
                        "heading": "Estado de verificación cambiado"
                    },
                    "domain-compliance-drop": {
                        "description": "Notificarme cuando el cumplimiento caiga por debajo de un umbral.",
                        "heading": "Cumplimiento caído"
                    },
                    "domain-created": {
                        "description": "Notificarme cuando se agregue un nuevo dominio.",
                        "heading": "Dominio creado"
                    },
                    "domain-deleted": {
                        "description": "Notificarme cuando se elimine un dominio.",
                        "heading": "Dominio eliminado"
                    },
                    "domain-score-change": {
                        "description": "Notificarme cuando la puntuación de cumplimiento DMARC haya cambiado.",
                        "heading": "Se modificó la puntuación de cumplimiento de DMARC"
                    },
                    "domain-settings-change": {
                        "description": "Notificarme cuando se cambie la configuración.",
                        "heading": "Cambios en la configuración"
                    },
                    "sender-threat-level-change": {
                        "description": "Notificarme cuando aumente el nivel de amenaza para remitentes nuevos o existentes.",
                        "heading": "Nivel de amenaza aumentado"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Notificarme cuando se detecte un remitente nuevo y comúnmente aprobado.",
                        "heading": "Remitente normalmente autorizado encontrado"
                    }
                }
            },
            "create": {
                "title": "Crear alerta"
            },
            "edit": {
                "title": "Editar alerta"
            },
            "email": {
                "description": "Especifique el destinatario del correo electrónico al que desea notificar y personalice el correo electrónico.",
                "heading": "Correo electrónico"
            },
            "email_settings": {
                "heading": "Personalizar correo electrónico"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Cliente",
                        "domain": "Dominio"
                    }
                },
                "frequency": {
                    "input_label": "Frecuencia"
                },
                "recipients": {
                    "input_label": "Destinatarios"
                },
                "scope": {
                    "input_label": "Nivel de supervisión",
                    "listing-title": ":scope Actividad",
                    "options": {
                        "customer": "Cliente",
                        "partner": "Partner",
                        "system": "Sistema"
                    }
                },
                "select_below": {
                    "description": "Verificación de configuración",
                    "input_label": "Seleccione a continuación"
                },
                "start_date": {
                    "input_label": "Fecha de inicio"
                },
                "status": {
                    "input_label": "Estado"
                },
                "subject_tags": {
                    "example": "p.ej. [gravedad-alerta][cuenta][paquete][dominio]",
                    "input_label": "Habilitar prefijos de categorización de líneas de asunto"
                }
            },
            "frequency": {
                "description": "Elija con qué frecuencia desea recibir esta notificación.",
                "disabled-text": "No disponible: esta es una actividad basada en eventos.",
                "heading": "Frecuencia",
                "options": {
                    "monthly": "Mensual",
                    "quarterly": "Trimestral",
                    "six_monthly": "Semestral",
                    "two_monthly": "Dos meses",
                    "yearly": "Anual"
                },
                "starting": "A partir de"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerta",
                        "frequency": "Frecuencia",
                        "recipients": "Destinatarios",
                        "status": "Estado"
                    },
                    "record": {
                        "alert_activity": ":scope Actividad"
                    }
                },
                "title": "Alertas"
            },
            "monitor": {
                "description": "Seleccione la actividad que desea monitorear.",
                "heading": "Monitor"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "A diario",
                        "fortnightly": "Quincenal",
                        "hourly": "Cada hora",
                        "immediate": "Inmediato",
                        "monthly": "Mensual",
                        "quarterly": "Trimestral",
                        "six_monthly": "Semestral",
                        "two_monthly": "Dos meses",
                        "weekly": "Semanalmente",
                        "yearly": "Anual"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "La alerta está inactiva",
                        "value": "apagado"
                    },
                    "on": {
                        "tooltip": "La alerta está activa",
                        "value": "en"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Deshabilitar el hosting de registros BIMI",
                "heading": "El servicio de hosting del registro BIMI está actualmente habilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar fácilmente su logotipo y certificado.",
                "modal": {
                    "header": "Deshabilitar el hosting del registro BIMI para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar hosting de registros BIMI",
                "heading": "El hosting BIMI está deshabilitado",
                "message": "Se recomienda que BIMI Hosting esté habilitado para administrar su Logotipo y Certificado.",
                "modal": {
                    "header": "Habilitar hosting de registros BIMI :domain",
                    "message": "Al habilitar BIMI Hosting, puede administrar fácilmente su logotipo y certificado."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificado CN",
                    "expiry": "Caducidad del certificado",
                    "input_label": "Certificado de marca verificada BIMI (Tipo de archivo: pem)",
                    "url": "URL de autoridad\/certificado"
                },
                "image": {
                    "input_label": "Logotipo BIMI (Tipo de archivo: svg)",
                    "preview": "Vista previa del logotipo",
                    "size": "Tamaño del logotipo",
                    "url": "URL del logotipo"
                }
            },
            "info": {
                "heading": "¿Qué es BIMI?",
                "message": "BIMI (Indicadores de marca para la identificación de mensajes) es un estándar que facilita que su logotipo se muestre junto a sus mensajes en la bandeja de entrada, previene correos electrónicos fraudulentos y facilita la entrega. BIMI trabaja junto con DMARC, DKIM y SPF para verificar la autenticidad de un correo electrónico y ayudar con la visibilidad de la marca."
            },
            "settings": {
                "heading": "Ajustes"
            },
            "title": "Editar configuración - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Volver a configuración",
                "share": "Compartir instrucciones de configuración",
                "verify": "Verificar",
                "view": "Ver instrucciones de configuración"
            },
            "bimi": {
                "description": "Actualice su DNS para permitir la configuración de las claves públicas BIMI de su dominio.",
                "modal": {
                    "not_configured": {
                        "header": "Instrucciones de configuración no disponibles",
                        "message": "Para generar registros DNS para su configuración BIMI, debe cargar un logotipo."
                    }
                }
            },
            "description": "Para habilitar la supervisión y gestión a través de DMARC, implemente los siguientes cambios en la configuración DNS de su dominio.",
            "dkim": {
                "description": "Agregue las siguientes entradas a los registros DNS de su dominio para permitir la configuración de sus claves públicas DKIM.",
                "no_nameservers": "La zona de clave pública DKIM se está creando, por favor espere las instrucciones de configuración..."
            },
            "dmarc": {
                "description": "Actualice su DNS para permitir la configuración de la directiva DMARC de su dominio y capturar informes sobre la actividad de autenticación de correo electrónico a través de nuestro panel.",
                "warning": "Tome nota de los guiones bajos requeridos (_) en el host y los puntos en el valor del registro."
            },
            "existing_dns_record_found": "Registro :record existente encontrado",
            "heading": ":title Instrucciones de configuración",
            "instructions": {
                "add": "Agregar registro :rdType",
                "heading": ":rdType Instrucciones de configuración",
                "new": "Paso 2: Agregar nuevo registro :rdType",
                "remove": "Paso 1: eliminar el registro :rdType existente"
            },
            "mta_policy": {
                "description": "Actualice su DNS para permitir la configuración de la directiva MTA de su dominio.",
                "heading": "Directiva MTA-STS"
            },
            "mta_record": {
                "description": "Actualice su DNS para permitir la configuración del registro MTA de su dominio.",
                "heading": "Registro MTA-STS"
            },
            "record": {
                "host": "Servidor",
                "type": "Tipo de registro",
                "value": "Valor"
            },
            "spf": {
                "description": "Actualice su DNS para permitir la configuración del registro SPF de su dominio a través de nuestra plataforma. Nuestra plataforma garantiza la validez del registro y utiliza tecnología de optimización que mitiga el riesgo de alcanzar los límites de búsqueda de DNS, además de garantizar que su directiva no sea demasiado permisiva."
            },
            "status": {
                "last_date": "Última verificación el",
                "pending": "Pendiente de verificación",
                "verified": "Verificado"
            },
            "tls_rpt": {
                "description": "Actualice su DNS para permitir la configuración del registro TLS-RPT de su dominio y capturar informes.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Administrar clientes"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volumen de correo electrónico",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobados y otros"
                    }
                },
                "domain_totals": {
                    "action": "Ver dominios",
                    "enforced": "aplicado",
                    "heading": "Dominios",
                    "reporting": "Informes",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Ver remitentes",
                    "heading": "Remitentes",
                    "legends": {
                        "approved": "Aprobado",
                        "unapproved": "No aprobado"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Ver todos los dominios"
                },
                "legends": {
                    "failing": "DMARC fallido",
                    "forwards": "Hacia adelante",
                    "passing": "Pasando DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Ver informe"
                    },
                    "header": {
                        "approved": "Aprobado",
                        "domain": "Dominios",
                        "senders": "Remitentes",
                        "unapproved": "No aprobado",
                        "volume": "Volumen"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Ver informe de dominio"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Cumplimiento",
                        "domain": "Dominio",
                        "sender": "Remitentes aprobados por volumen",
                        "volume": "Volumen"
                    }
                }
            },
            "title": "Panel"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "No se pudieron encontrar servidores de nombres coincidentes.",
                        "mismatch": "Los registros del servidor de nombres no coinciden con la configuración esperada.",
                        "missing": "Deben estar presentes al menos dos registros de servidores de nombres."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "El modo de alineación DKIM no coincide con la configuración.",
                        "missing": "Falta el modo de alineación DKIM, pero no está configurado al valor predeterminado."
                    },
                    "aspf": {
                        "mismatch": "El modo de alineación SPF no coincide con la configuración.",
                        "missing": "Falta el modo de alineación SPF, pero no está configurado al valor predeterminado."
                    },
                    "cname": {
                        "mismatch": "El registro CNAME no se ha añadido correctamente y no coincide con la configuración esperada.",
                        "partial": "El registro CNAME no se ha añadido correctamente. Esto puede causar conflictos."
                    },
                    "fo": {
                        "mismatch": "Las opciones de informes de fallos no coinciden con la configuración.",
                        "missing": "Faltan las opciones de informes de fallos, pero no están configuradas al valor predeterminado."
                    },
                    "p": {
                        "mismatch": "La directiva no coincide con la configuración.",
                        "missing": "Falta la directiva."
                    },
                    "pct": {
                        "mismatch": "El porcentaje de la directiva no coincide con la configuración.",
                        "missing": "Falta el porcentaje de la directiva, pero no está configurado al valor predeterminado."
                    },
                    "rf": {
                        "mismatch": "El formato de informes de fallos no coincide con la configuración."
                    },
                    "ri": {
                        "mismatch": "El intervalo de los informes agregados no coincide con la configuración.",
                        "missing": "Falta el intervalo de los informes agregados, pero no está configurado al valor predeterminado."
                    },
                    "rua": {
                        "mismatch": "Los destinatarios de los informes agregados no coinciden con la configuración.",
                        "order": "Los destinatarios de los informes agregados no están en el orden correcto."
                    },
                    "ruf": {
                        "mismatch": "Los destinatarios de los informes de fallos no coinciden con la configuración.",
                        "order": "Los destinatarios de los informes de fallos no están en el orden correcto."
                    },
                    "sp": {
                        "mismatch": "La directiva de subdominios no coincide con la configuración.",
                        "missing": "Falta la directiva de subdominios, pero está configurada de manera diferente a la de dominio."
                    },
                    "txt": {
                        "reporting-only": "El registro no coincide con la configuración esperada, pero nuestra dirección de informes está presente."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "El valor TXT no coincide con el valor esperado."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "El valor de CNAME no coincide con el valor esperado.",
                        "missing": "No se pudo encontrar un registro CNAME coincidente."
                    },
                    "rua": {
                        "missing": "Falta la dirección de informes esperada."
                    }
                }
            },
            "verify": {
                "error": "Verificación fallida",
                "nxdomain": "Registro no encontrado.",
                "success": "Verificación completada con éxito",
                "timeout": "Tiempo de verificación agotado"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Agregar nueva clave pública",
                "edit_public_key": {
                    "tooltip": "Editar clave pública"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva clave pública"
            },
            "disable": {
                "action": "Gestión DKIM deshabilitada",
                "heading": "El hosting de clave pública DKIM está actualmente habilitado",
                "message": "Se recomienda habilitar la gestión de DKIM para ayudarle a administrar sus claves públicas.",
                "modal": {
                    "header": "Deshabilitar la administración DKIM para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "edit_drawer": {
                "header": "Editar clave pública"
            },
            "enable": {
                "action": "Habilitar la gestión DKIM",
                "heading": "El hosting de clave pública DKIM está deshabilitado",
                "message": "Se recomienda que la administración DKIM esté habilitada para ayudarle a administrar sus claves públicas.",
                "modal": {
                    "header": "Habilitar la administración DKIM para :domain",
                    "message": "Al habilitar la administración DKIM, puede administrar sus claves públicas con facilidad."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "record_type": {
                    "input_label": "Tipo de registro"
                },
                "record_value": {
                    "example": "p.ej. dkim.ejemplo.com",
                    "input_label": "Valor de registro"
                },
                "selector": {
                    "input_label": "Selector"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación de clave pública DKIM completada"
                },
                "disabled_tooltip": "Las claves públicas DKIM solo se pueden importar para dominios que tengan informes.",
                "header": "Volver a importar claves públicas DKIM para :domain",
                "message": "Todas las claves públicas existentes se eliminarán y reemplazarán con directivas importadas."
            },
            "info": {
                "heading": "¿Qué es DKIM?",
                "message": "Domain Keys Identified Mail es un método de autenticación de correos electrónicos que permite al remitente firmar digitalmente un correo utilizando una clave privada asociada con su nombre de dominio, la cual puede ser verificada por el servidor de correo del destinatario mediante una clave pública publicada en los registros DNS del remitente. Esto ayuda a verificar la autenticidad e integridad del correo, reduciendo el riesgo de ataques de spam y phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Directiva de firma saliente",
                        "options": {
                            "all": "Todos los correos electrónicos",
                            "some": "Algunos correos electrónicos"
                        }
                    },
                    "testing": {
                        "input_label": "Modo de prueba"
                    }
                },
                "heading": "Configuración de directivas"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "value": "Valor"
                    }
                }
            },
            "title": "Editar configuración - DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Modo de alineación DKIM"
                    },
                    "options": {
                        "r": "Relajado",
                        "s": "Estricto"
                    },
                    "spf_mode": {
                        "input_label": "Modo de alineación SPF"
                    }
                },
                "heading": "Configuración de alineación"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Aplicar una directiva de subdominio diferente"
                    },
                    "percentage": {
                        "input_label": "Porcentaje"
                    },
                    "policy": {
                        "input_label": "directiva DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "directiva de subdominio"
                    }
                },
                "heading": "Configuración de directivas"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Los informes se enviarán a"
                    },
                    "interval": {
                        "input_label": "Intervalo de informe",
                        "options": {
                            "1_day": "Una vez al día",
                            "1_hour": "Cada hora",
                            "2_hours": "Cada 2 horas",
                            "4_hours": "Cada 4 Horas",
                            "6_hours": "Cada 6 horas"
                        }
                    }
                },
                "heading": "Informes agregados"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Permitir",
                    "header": "Habilitar informes de errores",
                    "message": "Al habilitar los informes de fallos, cierta información personal, como direcciones de correo electrónico o el contenido de los mensajes, puede ser expuesta con fines de diagnóstico.",
                    "reject": "Desactivar"
                },
                "fields": {
                    "options": {
                        "input_label": "Opciones de informe",
                        "options": {
                            "d": "DKIM falla independientemente de la alineación",
                            "s": "El SPF falla independientemente de la alineación",
                            "0": "SPF o DKIM fallan y no se alinean",
                            "1": "SPF y DKIM fallan y no se alinean"
                        }
                    },
                    "status": {
                        "input_label": "Procesamiento de informes",
                        "options": {
                            "disabled": "Desactivado",
                            "enabled": "Activado",
                            "specified": "Destinatarios específicos"
                        }
                    }
                },
                "heading": "Informes de fallos"
            },
            "title": "Editar configuración - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificado de cumplimiento DMARC",
                "domain_report": "Informe de dominio",
                "edit_settings": {
                    "tooltip": "Editar configuración"
                },
                "remove_primary": "Quitar de Favoritos",
                "set_as_active": "Establecer como activo",
                "set_as_parked": "Establecer como estacionado",
                "set_primary": "Marcar como favorito",
                "view_logs": "Ver registros"
            },
            "create_drawer": {
                "header": "Agregar nuevo dominio"
            },
            "fields": {
                "name": {
                    "example": "p.ej. 'ejemplo.com' o 'subdominio.ejemplo.com'",
                    "input_label": "Nombre de dominio"
                },
                "type": {
                    "input_label": "Tipo de dominio",
                    "options": {
                        "active": "Activo",
                        "parked": "estacionado"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Dominio",
                        "policy": "directiva",
                        "score": "Puntuación",
                        "status": "Estado",
                        "type": "Tipo"
                    }
                },
                "title": "Dominios"
            },
            "logs_drawer": {
                "header": "Dominio"
            },
            "report_modal": {
                "action": "Generar enlace",
                "fields": {
                    "copy_link": {
                        "input_label": "Copie el siguiente enlace para compartir el informe de dominio"
                    },
                    "date_range": {
                        "input_label": "Rango de fechas"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Informe de dominio"
            },
            "settings": {
                "title": "Editar configuración"
            },
            "setup_modal": {
                "action": "Generar enlace",
                "fields": {
                    "copy_link": {
                        "input_label": "Enlace a las instrucciones de configuración"
                    },
                    "expire_link": {
                        "input_label": "Caducar enlace en",
                        "options": {
                            "1_day": "Un día",
                            "1_month": "un mes",
                            "1_week": "una semana",
                            "6_hours": "6 horas",
                            "12_hours": "12 horas",
                            "never": "Nunca"
                        }
                    }
                },
                "header": "Enlace a las instrucciones de configuración"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Ninguno"
                    },
                    "quarantine": {
                        "value": "Cuarentena"
                    },
                    "reject": {
                        "value": "Rechazar"
                    },
                    "tooltip": "directiva aplicada"
                },
                "primary": {
                    "tooltip": "Favorito",
                    "value": "Favorito"
                },
                "score": {
                    "high": {
                        "tooltip": "Alto riesgo"
                    },
                    "low": {
                        "tooltip": "Bajo riesgo"
                    },
                    "moderate": {
                        "tooltip": "Riesgo moderado"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Verificado, sin informar",
                        "tooltip_partial": "Parcialmente verificado, sin informar",
                        "value": "No informar"
                    },
                    "not_verified": {
                        "tooltip": "No verificado, no reportado",
                        "value": "No verificado"
                    },
                    "reporting": {
                        "tooltip": "Verificado, Informes",
                        "tooltip_partial": "Parcialmente verificado, informes",
                        "value": "Informes"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Dominio activo",
                        "value": "Activo"
                    },
                    "parked": {
                        "tooltip": "Dominio estacionado",
                        "value": "estacionado"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "p.ej. destinatario@ejemplo.com",
                    "input_label": "Editar correo electrónico",
                    "placeholder": "Ingrese la dirección de correo electrónico",
                    "subscribed_label": "suscrito",
                    "unsubscribed_label": "Cancelar suscripción",
                    "validation_message": "Ingrese una dirección de correo electrónico válida"
                }
            }
        },
        "errors": {
            "menu": "Error",
            "401": {
                "description": "Lo sentimos, no estás autorizado a acceder a esta página.",
                "title": "401: no autorizado"
            },
            "403": {
                "description": "Lo sentimos, tienes prohibido acceder a esta página.",
                "title": "403: Prohibido"
            },
            "404": {
                "description": "Lo sentimos, no se pudo encontrar la página que estás buscando.",
                "title": "404: Página no encontrada"
            },
            "500": {
                "description": "Vaya, algo salió mal en nuestros servidores.",
                "title": "500: Error del servidor"
            },
            "502": {
                "description": "Lo sentimos, nuestros servidores no están disponibles.",
                "title": "502 Puerta de enlace incorrecta"
            },
            "503": {
                "description": "Lo sentimos, estamos realizando tareas de mantenimiento. Por favor, vuelva a intentarlo pronto.",
                "title": "503: Servicio no disponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volumen de correo electrónico",
                "legends": {
                    "failing": "DMARC fallido",
                    "forward": "Hacia adelante",
                    "passing": "DMARC aprobado"
                }
            },
            "heading": "Informe de dominio",
            "recommendations": {
                "heading": "Recomendaciones"
            },
            "risk_analysis": {
                "heading": "Análisis de riesgos"
            },
            "scoring": {
                "note": "Nota: La puntuación del dominio se calcula al momento de generar el informe y es posible que no esté actualizada al momento de verlo."
            },
            "sender_readiness": {
                "heading": "Preparación de DMARC del remitente",
                "legends": {
                    "configured": {
                        "description": "La preparación de DMARC es una medida de la configuración necesaria para implementar el cumplimiento total.",
                        "heading": "Configurado"
                    },
                    "partially": {
                        "description": "Las fuentes parcialmente configuradas tienen al menos un mecanismo de autorización activo; sin embargo, es posible que sea necesaria una configuración adicional.",
                        "heading": "Parcialmente configurado"
                    },
                    "unconfigured": {
                        "description": "Fuentes ilegítimas o no configuradas son fuentes que envían correo electrónico desde su dominio y que pueden estar suplantando su marca.",
                        "heading": "Ilegítimo o no configurado"
                    }
                }
            },
            "title": "Informe de dominio para :domain",
            "top_countries": {
                "heading": "Principales países de origen: volumen de correo electrónico"
            },
            "top_senders": {
                "heading": "Principales remitentes: Cumplimiento"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Descargar Informe",
                "header": "Informe de falla",
                "menu": {
                    "key_properties": "Propiedades clave",
                    "more_info": "Más información"
                }
            },
            "properties": {
                "authentication_failure": "Fallo de autenticación",
                "date": "Fecha",
                "delivery_results": "Resultados de entrega",
                "dkim_domain": "Dominio DKIM",
                "dkim_header": "Encabezado canonicalizado DKIM",
                "dkim_identity": "Identidad DKIM",
                "dkim_selector": "Selector DKIM",
                "dmarc_version": "Versión DMARC",
                "feedback_type": "Tipo de comentario",
                "incidents": "Número de incidentes",
                "original_recipients": "Destinatarios originales",
                "reporting_domains": "Dominios reportados",
                "reporting_mta": "MTA de informes",
                "reporting_user_agent": "Agente de usuario de informes",
                "return_path": "Camino de regreso",
                "source_ip": "Dirección IP de origen",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Autenticación",
                    "date": "Fecha",
                    "ip_address": "Dirección IP",
                    "result": "Resultado",
                    "return_path": "Camino de regreso"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Entregado",
                    "none": "Ninguno, Aceptar",
                    "smg_policy_action": "Directiva aplicada",
                    "spam": "Rechazado",
                    "unknown": "Desconocido"
                }
            },
            "title": "Informes de fallos para :domain"
        },
        "global": {
            "buttons": {
                "add": "Agregar",
                "add_new": "Agregar nuevo",
                "back": "Atrás",
                "cancel": "Cancelar",
                "delete": "Borrar",
                "import": "Importar",
                "next": "Próximo",
                "no": "No",
                "open_link": "Abrir enlace",
                "remove": "Eliminar",
                "save": "Guardar",
                "save_exit": "Guardar y volver",
                "select": "Seleccionar",
                "yes": "Sí"
            },
            "confirm_delete": "¿Quieres continuar?",
            "copied": "copiado",
            "created": "Creado",
            "deleted": "Eliminado",
            "fail": "Fallar",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Desactivado",
                        "enabled": "Activado"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Apagado",
                        "on": "En"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "No",
                        "yes": "Sí"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Error: complete todos los campos obligatorios correctamente.",
                    "title": "Hubo errores con su formulario."
                },
                "success": {
                    "title": "Tus cambios fueron guardados."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volumen"
                }
            },
            "logout": "Cerrar sesión",
            "not_found": "No encontrado",
            "pagination": {
                "of": "de",
                "showing": "Demostración",
                "to": "a"
            },
            "pass": "Aprobar",
            "remove": "Eliminar",
            "show_less": "Mostrar menos",
            "show_more": "Mostrar más",
            "system": "Sistema",
            "theme": {
                "dark": "Oscuro",
                "light": "Claro"
            },
            "to": "A"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Rango de fechas",
                "to": "a"
            },
            "menu": {
                "overrides": "Anulaciones",
                "overview": "Descripción general",
                "reporters": "Reporteros",
                "results": "Resultados"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Directiva aplicada 'Ninguna'",
                    "policy_applied_quarantine": "Directiva aplicada 'Cuarentena'",
                    "policy_applied_reject": "Directiva aplicada 'Rechazar'"
                },
                "overridden_policy_breakdown": "Desglose de directivas anuladas",
                "volume_overridden_policy": "Volumen con directivas anuladas"
            },
            "overview": {
                "cards": {
                    "compliance": "Cumplimiento",
                    "deliverability": "Capacidad de entrega",
                    "volume": "Volumen"
                },
                "properties": {
                    "country": "País",
                    "host": "HOST",
                    "isp": "ISP",
                    "organisation": "Organización",
                    "sender": "Remitente"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Cumplimiento",
                        "envelope_from": "Sobre de",
                        "envelope_to": "Sobre a",
                        "header_from": "Encabezado desde",
                        "no_data": "No hay datos de reportes disponibles.",
                        "percent_of_total": "% del total",
                        "reporter": "Reportero",
                        "volume": "Volumen"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alineación",
                    "error": "Error",
                    "fail": "Fallar",
                    "failed": "Fallido\/No alineado",
                    "none": "Ninguno",
                    "pass": "Aprobar",
                    "passed": "Aprobado\/Alineado"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Acción aplicada",
                    "info": {
                        "tooltip": "Acción tomada por el receptor"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Entregado",
                            "value": "Entregado"
                        },
                        "quarantine": {
                            "tooltip": "En cuarentena",
                            "value": "En cuarentena"
                        },
                        "reject": {
                            "tooltip": "Rechazado",
                            "value": "Rechazado"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alineación",
                            "dmarc_via_dkim": "DMARC a través de DKIM",
                            "results": "Resultados",
                            "selector": "Selector",
                            "signing_domain": "Dominio de firma"
                        },
                        "heading": "Detalles de DKIM"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM no alineado",
                            "no_info": "Sin información DKIM",
                            "yes": "DKIM alineado"
                        },
                        "header": "Resultados DKIM",
                        "no_details": "Sin detalles de firma DKIM",
                        "pass_tooltips": {
                            "no": "DKIM falló",
                            "none": "DKIM Ninguno",
                            "yes": "Pase DKIM"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "El receptor aplicó la directiva de ':policy'"
                        },
                        "header": {
                            "from_domain": "Desde dominio"
                        },
                        "heading": "Otros detalles"
                    },
                    "results": {
                        "header": "Resultados DMARC",
                        "pass_tooltips": {
                            "no": "DMARC falló",
                            "yes": "DMARC aprobado"
                        }
                    }
                },
                "override_comment": {
                    "header": "Comentario"
                },
                "override_type": {
                    "header": "Tipo de anulación"
                },
                "published_policy": {
                    "header": "Directiva publicada",
                    "info": {
                        "tooltip": "Directiva DMARC en el momento de la validación."
                    }
                },
                "reporter": {
                    "header": "Reportero"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alineación",
                            "dmarc_via_spf": "DMARC vía SPF",
                            "result": "Resultado",
                            "return_path_domain": "Dominio de ruta de retorno"
                        },
                        "heading": "Detalles del FPS"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF no alineado",
                            "no_info": "Sin información SPF",
                            "yes": "SPF alineado"
                        },
                        "header": "Resultados del FPS",
                        "no_details": "Sin detalles de SPF",
                        "pass_tooltips": {
                            "error": "Error temporal del SPF",
                            "no": "FPS fallido",
                            "none": "SPF Ninguno",
                            "yes": "Pase SPF"
                        }
                    }
                },
                "volume": {
                    "header": "Volumen"
                }
            }
        },
        "logs": {
            "events": {
                "created": "creado",
                "deleted": "eliminado",
                "restored": "restaurado",
                "updated": "actualizado"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificado",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Delegación habilitada",
                        "nameservers": "Servidores de nombres",
                        "selector": "Selector"
                    },
                    "title": "Configuración BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Descripción",
                        "record_type": "Tipo de registro",
                        "selector": "Selector",
                        "status": "Estado",
                        "ttl": "TTL",
                        "value": "Valor"
                    },
                    "title": "Clave pública DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Delegación habilitada",
                        "is_policy_enabled": "Directiva habilitada",
                        "nameservers": "Servidores de nombres",
                        "policy": "Directiva"
                    },
                    "title": "Configuración DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alineación DKIM",
                        "policy": "Directiva",
                        "policy_percentage": "Porcentaje de directiva",
                        "rua_emails": "Destinatarios del informe agregado",
                        "rua_interval": "Intervalo de informe agregado",
                        "ruf_emails": "Destinatarios del informe de fallos",
                        "ruf_format": "Formato de informe de fallos",
                        "ruf_options": "Opciones de informe de fallos",
                        "ruf_status": "Estado del informe de fallos",
                        "spf_alignment": "Alineación SPF",
                        "subdomain_policy": "Directiva de subdominio"
                    },
                    "title": "Configuración DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Mensajes",
                        "result": "Resultado",
                        "started_at": "Comenzó en",
                        "status": "Estado",
                        "type": "Tipo"
                    },
                    "title": "Verificación de delegación DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Dominio",
                        "is_favorite": "Favorito",
                        "logo": "Logo",
                        "score": "Puntuación de dominio",
                        "type": "Tipo"
                    },
                    "title": "Dominio :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Hosting de registros habilitado",
                        "mx_records": "Registros MX",
                        "policy": "Modo de directiva"
                    },
                    "title": "Configuración MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Descripción",
                        "mechanism": "Tipo de registro",
                        "order": "Orden",
                        "qualifier": "Clasificatorio récord",
                        "status": "Estado",
                        "value": "Valor del anfitrión"
                    },
                    "title": "Directivas SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Clasificatorio récord de alto nivel",
                        "enable_flattening": "Aplanamiento habilitado"
                    },
                    "title": "Configuración de SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT habilitado",
                        "rua_emails": "Informar correos electrónicos"
                    },
                    "title": "Configuración TLS-RPT :event"
                }
            },
            "header": ":type registros"
        },
        "menu": {
            "account": "Cuenta",
            "domains": "Dominios",
            "home": "Panel",
            "reports": {
                "email_senders": "Remitentes de correo electrónico",
                "failure_reports": "Informes de fallos",
                "reports": "Informes",
                "tls_rpt_reports": "Informes TLS-RPT"
            },
            "settings": {
                "alerts": "Alertas",
                "logout": "Cerrar sesión",
                "settings": "Ajustes"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Hosting MTA-STS deshabilitado",
                "heading": "El hosting MTA-STS está habilitado",
                "message": "Se recomienda que los informes TLS-RPT y el hosting de directivas MTA-STS estén habilitados.",
                "modal": {
                    "header": "Deshabilitar el hosting MTA-STS para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar hosting MTA-STS",
                "heading": "El hosting MTA-STS está deshabilitado",
                "message": "Se recomienda que el hosting de directivas y registros de MTA-STS esté habilitado para administrar sus registros de manera efectiva.",
                "modal": {
                    "header": "Habilite el hosting MTA-STS para :domain",
                    "message": "Al habilitar MTA-STS Hosting, puede administrar su registro y el hosting de su póliza."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Servidores MX"
                },
                "policy": {
                    "input_label": "Nivel de aplicación de directivas",
                    "options": {
                        "enforce": "aplicado",
                        "none": "Ninguno",
                        "testing": "En pruebas"
                    }
                }
            },
            "heading": "hosting de directivas y registros MTA-STS",
            "info": {
                "heading": "¿Qué TLS-RPT y MTA-STS?",
                "message": "TLS-RPT o SMTP TLS Reporting (Informes de seguridad de la capa de transporte) permite que los sistemas de envío compartan estadísticas e información sobre fallos con los dominios del destinatario. MTA-STS (Agentes de transferencia de correo: seguridad de transporte estricta) permite a los proveedores de servicios de correo declarar su capacidad para recibir conexiones SMTP seguras TLS."
            },
            "status": {
                "policy": {
                    "pending": "Directiva pendiente de verificación",
                    "verified": "Directiva verificada"
                },
                "record": {
                    "pending": "Registro pendiente de verificación",
                    "verified": "Registro verificado"
                }
            }
        },
        "partners": {
            "title": "Administrar partners"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurar los ajustes DMARC",
                        "manage_domains": "Administrar dominios"
                    },
                    "description": "Aún no hemos recibido datos de informes para su dominio los datos pueden tardar hasta 72 horas en aparecer. Los informes solo se pueden recuperar para dominios activos y verificados. Administre el resto de sus dominios yendo a la página de dominios.",
                    "header": "No hay datos de informes disponibles"
                },
                "no_reporting_data": {
                    "description": "No hay datos de informes disponibles para el intervalo de fechas seleccionado. Ajuste su selección e inténtelo de nuevo.",
                    "header": "No hay datos de informes disponibles"
                }
            },
            "senders": {
                "unknown": "Desconocida"
            }
        },
        "scoring": {
            "blurb": {
                "high": "No dispone de controles efectivos para proteger su dominio contra la suplantación y la interceptación de comunicaciones por correo electrónico. Esto pone en riesgo su marca y a los destinatarios de sus correos, reduce la confianza y puede afectar negativamente la entregabilidad de los correos electrónicos.",
                "low": "schützen die Empfänger vor Angriffen und sorgen für eine effektive Zustellung von E-Mails.",
                "moderate": "Ha implementado algunas medidas para proteger a los destinatarios de correos electrónicos maliciosos enviados desde su dominio. Sin embargo, se debe prestar atención a las áreas donde los controles pueden fortalecerse. Estas medidas ayudan a generar confianza en su organización, protegen a los destinatarios de ataques y aseguran una entregabilidad eficaz de los correos electrónicos."
            },
            "header": "Puntuación de dominio",
            "impersonation": "Interpretación",
            "marketing": "Marketing",
            "note": "NOTA: Esta es la puntuación actual de su dominio y puede no reflejar los cambios que haya realizado hoy.",
            "privacy": "Privacidad",
            "rating": {
                "high": "Alto riesgo",
                "low": "Bajo riesgo",
                "moderate": "Riesgo moderado"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volumen bloqueado",
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "deliverable": "Entregable",
                "deliverable_volume": "Volumen entregable",
                "ip_addresses": "Direcciones IP",
                "senders": "Remitentes",
                "volume": "Volumen de correo electrónico"
            },
            "categories": {
                "all": "Todos los remitentes",
                "authorised_compliant": "Aprobado",
                "forwarder": "Promotor",
                "suspicious": "Sospechoso",
                "unauthorised": "No aprobado",
                "unknown": "Ninguno"
            },
            "menu": {
                "compliance": "Cumplimiento",
                "deliverability": "Capacidad de entrega",
                "reputation": "Reputación",
                "threats": "Amenazas"
            },
            "messages": {
                "category_updated": {
                    "error": "No se pudo actualizar la categoría del remitente",
                    "success": "Categoría de remitente actualizada"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "En la lista negra",
                    "clean": "Limpio",
                    "compliant": "Conforme",
                    "delivered": "Entregado",
                    "failing_dmarc": "DMARC fallido",
                    "forward": "Reenvíos",
                    "not_delivered": "No entregado",
                    "overridden": "Anulado",
                    "overridden_as_delivered": "Anulado tal como se entregó",
                    "overridden_as_not_delivered": "Anulado como no entregado",
                    "passing": "Paso",
                    "passing_dkim": "Pasando solo DKIM",
                    "passing_dkim_spf": "Pasando DKIM y SPF",
                    "passing_dmarc": "Pasando DMARC",
                    "passing_spf": "Pasar solo SPF",
                    "senders": "Remitentes"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "IP de remitente aprobadas",
                        "approved_sender_volume": "Volumen de remitente aprobado",
                        "blacklisted": "En la lista negra",
                        "blacklists": "Listas negras",
                        "blocked_volume": "Volumen bloqueado",
                        "category": "Categoría",
                        "clean": "Limpio",
                        "compliance": "Cumplimiento",
                        "country": "País",
                        "deliverability": "Capacidad de entrega",
                        "delivered": "Entregado",
                        "failing": "Defecto",
                        "forwards": "Reenvíos",
                        "host": "Servidor",
                        "ip": "IP",
                        "ip_addresses": "Direcciones IP",
                        "not_delivered": "No entregado",
                        "passing": "Paso",
                        "sender": "Remitente",
                        "top_blacklisted_senders": "Principales remitentes incluidos en la lista negra",
                        "volume": "Volumen"
                    },
                    "info": {
                        "blacklists": "Cantidad de listas negras en las que se ha incluido la dirección IP"
                    }
                }
            },
            "title": "Remitentes de correo electrónico para :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Agregar nueva directiva SPF",
                "edit_record": {
                    "tooltip": "Editar registro"
                }
            },
            "create_drawer": {
                "header": "Agregar nueva directiva SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directivas",
                        "order": "Orden"
                    }
                },
                "titles": {
                    "allow_a_none": "Permitir registros A para su propio dominio",
                    "allow_a_value": "Permitir registros A para :value",
                    "allow_exists": "Permitir el macro de existencia :value",
                    "allow_include": "Incluir :value",
                    "allow_ip4_address": "Incluir la dirección IPv4 :value",
                    "allow_ip4_addresses": "Incluir las direcciones IPv4 en :value",
                    "allow_ip6_address": "Incluir la dirección IPv6 :value",
                    "allow_ip6_addresses": "Incluir las direcciones IPv6 en :value",
                    "allow_mx_none": "Permitir registros MX para su propio dominio",
                    "allow_mx_value": "Permitir registros MX para :value",
                    "fail_a_none": "Fallar registros A para su propio dominio",
                    "fail_a_value": "Fallar registros A para :value",
                    "fail_exists": "Fallar el macro de existencia :value",
                    "fail_include": "Fallar al incluir :value",
                    "fail_ip4_address": "Fallar la dirección IPv4 :value",
                    "fail_ip4_addresses": "Fallar las direcciones IPv4 en :value",
                    "fail_ip6_address": "Fallar la dirección IPv6 :value",
                    "fail_ip6_addresses": "Fallar las direcciones IPv6 en :value",
                    "fail_mx_none": "Fallar registros MX para su propio dominio",
                    "fail_mx_value": "Fallar registros MX para :value",
                    "neutral_a_none": "Ser neutral en registros A para su propio dominio",
                    "neutral_a_value": "Ser neutral en registros A para :value",
                    "neutral_exists": "Ser neutral en el macro de existencia :value",
                    "neutral_include": "Ser neutral al incluir :value",
                    "neutral_ip4_address": "Ser neutral en la dirección IPv4 :value",
                    "neutral_ip4_addresses": "Ser neutral en las direcciones IPv4 en :value",
                    "neutral_ip6_address": "Ser neutral en la dirección IPv6 :value",
                    "neutral_ip6_addresses": "Ser neutral en las direcciones IPv6 en :value",
                    "neutral_mx_none": "Ser neutral en registros MX para su propio dominio",
                    "neutral_mx_value": "Ser neutral en registros MX para :value",
                    "soft_fail_a_none": "Fallar levemente registros A para su propio dominio",
                    "soft_fail_a_value": "Fallar levemente registros A para :value",
                    "soft_fail_exists": "Fallar levemente el macro de existencia :value",
                    "soft_fail_include": "Fallar levemente al incluir :value",
                    "soft_fail_ip4_address": "Fallar levemente la dirección IPv4 :value",
                    "soft_fail_ip4_addresses": "Fallar levemente las direcciones IPv4 en :value",
                    "soft_fail_ip6_address": "Fallar levemente la dirección IPv6 :value",
                    "soft_fail_ip6_addresses": "Fallar levemente las direcciones IPv6 en :value",
                    "soft_fail_mx_none": "Fallar levemente registros MX para su propio dominio",
                    "soft_fail_mx_value": "Fallar levemente registros MX para :value"
                }
            },
            "edit_drawer": {
                "header": "Editar directiva SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Descripción"
                },
                "host": {
                    "example": "p.ej. _spf.ejemplo.com",
                    "input_label": "Valor :type"
                },
                "mechanism": {
                    "input_label": "Tipo de registro"
                },
                "qualifier": {
                    "input_label": "Clasificatorio récord",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (no recomendado)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Actualizar una directiva SPF",
                "use_own_domain": {
                    "input_label": "Dominio del mecanismo"
                }
            },
            "import_modal": {
                "action": "Eliminar e importar",
                "complete": {
                    "message": "Importación SPF completada"
                },
                "header": "Importar registros y directivas SPF existentes para :domain",
                "message": "Todas las directivas existentes serán eliminadas y reemplazadas por directivas importadas."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Clasificatorio récord de alto nivel",
                        "options": {
                            "allow": "+ Pass (no recomendado)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (no recomendado)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+todos Configurado para pasar la autenticación de correos electrónicos de remitentes que no están autorizados.",
                            "-": "Configurado para fallar la autenticación de correos electrónicos de remitentes no autorizados.",
                            "?": "Configurado como neutral, lo que ni aprueba ni falla la autenticación de correos electrónicos de remitentes no autorizados.",
                            "~": "Configurado para fallo leve en la autenticación de correos electrónicos de remitentes no autorizados y tratarlos como sospechosos."
                        }
                    },
                    "flattening": {
                        "input_label": "Habilitar aplanamiento"
                    }
                },
                "heading": "Ajustes"
            },
            "title": "Editar configuración - SPF"
        },
        "tables": {
            "clear": "Claro",
            "not_found": "No se encontraron registros coincidentes",
            "search": "Buscar"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurar los ajustes de TLS-RPT",
                    "description": "Aún no hemos recibido datos de informes para su dominio los datos pueden tardar hasta 72 horas en aparecer. Los informes TLS-RPT solo se pueden recuperar para dominios que tengan habilitados los informes TLS-RPT.",
                    "header": "No hay datos de informes disponibles"
                }
            },
            "heading": "Informes TLS-RPT para :domain",
            "reporters": {
                "heading": "Informes",
                "legend": {
                    "reports": "Informes"
                },
                "table": {
                    "header": {
                        "policy_mode": "Modo de directiva",
                        "policy_type": "Tipo de directiva",
                        "reporter": "Reportero",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Línea de tiempo",
                "legend": {
                    "failure": "Fallido",
                    "success": "Exitoso"
                }
            },
            "title": "Informes TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Deshabilitar informes TLS-RPT",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Informes TLS-RPT deshabilitados para :domain",
                    "message": "Se eliminarán todas las configuraciones y ajustes de este dominio esta acción no se puede deshacer."
                }
            },
            "enable": {
                "action": "Habilitar informes TLS-RPT",
                "heading": "Los informes TLS-RPT están deshabilitados",
                "message": "Se recomienda habilitar los informes TLS-RPT para monitorear los informes.",
                "modal": {
                    "header": "Habilitar informes TLS-RPT para :domain",
                    "message": "Al habilitar los informes TLS-RPT, puede monitorear estadísticas e información sobre fallos en los dominios de los destinatarios."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Los informes se enviarán a"
                }
            },
            "heading": "Informes TLS-RPT",
            "title": "Editar configuración: TLS-RPT"
        },
        "upload": {
            "add": "Agregar archivos",
            "drop_zone": "Arrastre y suelte el archivo aquí para cargarlo.",
            "title": "Subir"
        },
        "users": {
            "email": {
                "edit": "Editar correo electrónico",
                "placeholder": "Introduzca la dirección de correo electrónico"
            },
            "menu": "Usuarios",
            "name": {
                "title": "Nombre"
            },
            "title": "Administrar usuarios"
        },
        "validation": {
            "email": "La dirección de correo electrónico no es válida",
            "invalid_attr": "El :attribute no es válido."
        }
    },
    "fr": {
        "alerts": {
            "actions": {
                "create_activate": "Créer et activer",
                "edit_alert": {
                    "tooltip": "Modifier l'alerte"
                }
            },
            "activity": {
                "categories": {
                    "configuration": "Configuration",
                    "mail_senders": "Expéditeurs de courrier",
                    "none": "Aucun",
                    "reports": "Rapports",
                    "status": "Statut"
                },
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Activité",
                "options": {
                    "customer-created": {
                        "description": "M'avertir lorsqu'un compte est créé.",
                        "heading": "Client créé"
                    },
                    "customer-deleted": {
                        "description": "M'avertir lorsqu'un compte est supprimé.",
                        "heading": "Client supprimé"
                    },
                    "customer-exposure-report": {
                        "description": "Planifiez le moment où vous souhaitez recevoir le rapport d’exposition.",
                        "heading": "Rapport d'exposition"
                    },
                    "dmarc-reject-certificate": {
                        "description": "Planifiez le certificat de conformité DMARC lorsque la stratégie DMARC est définie sur Rejeter.",
                        "heading": "Certificat de conformité DMARC en cas de rejet"
                    },
                    "dns-delegation-unverified": {
                        "description": "Avertissez-moi lorsque la re-vérification de la délégation DNS échoue.",
                        "heading": "Échec de la revérification de l'installation"
                    },
                    "dns-delegation-verified": {
                        "description": "Notifier où le statut de vérification a changé",
                        "heading": "Statut de vérification modifié"
                    },
                    "domain-compliance-drop": {
                        "description": "Avertissez-moi lorsque la conformité descend en dessous d'un seuil.",
                        "heading": "Conformité abandonnée"
                    },
                    "domain-created": {
                        "description": "Avertissez-moi lorsqu'un nouveau domaine est ajouté.",
                        "heading": "Domaine créé"
                    },
                    "domain-deleted": {
                        "description": "M'avertir lorsqu'un domaine est supprimé.",
                        "heading": "Domaine supprimé"
                    },
                    "domain-score-change": {
                        "description": "Avertissez-moi lorsque le score de conformité DMARC a changé.",
                        "heading": "Score de conformité DMARC modifié"
                    },
                    "domain-settings-change": {
                        "description": "Avertissez-moi lorsque les paramètres sont modifiés.",
                        "heading": "Modifications des paramètres"
                    },
                    "sender-threat-level-change": {
                        "description": "Avertissez-moi lorsque le niveau de menace augmente pour les expéditeurs nouveaux ou existants.",
                        "heading": "Niveau de menace augmenté"
                    },
                    "typically-authorized-sender-found": {
                        "description": "Avertissez-moi lorsqu'un nouvel expéditeur généralement approuvé est détecté.",
                        "heading": "Expéditeur autorisé trouvé"
                    }
                }
            },
            "create": {
                "title": "Créer une alerte"
            },
            "edit": {
                "title": "Modifier l'alerte"
            },
            "email": {
                "description": "Spécifiez le destinataire de l'e-mail que vous souhaitez informer et personnalisez l'e-mail.",
                "heading": "E-mail"
            },
            "email_settings": {
                "heading": "Personnaliser l'e-mail"
            },
            "fields": {
                "activity_type": {
                    "options": {
                        "customer": "Client",
                        "domain": "Domaine"
                    }
                },
                "frequency": {
                    "input_label": "Fréquence"
                },
                "recipients": {
                    "input_label": "Destinataires"
                },
                "scope": {
                    "input_label": "Niveau de surveillance",
                    "listing-title": ":scope Activité",
                    "options": {
                        "customer": "Client",
                        "partner": "Partenaire",
                        "system": "Système"
                    }
                },
                "select_below": {
                    "description": "Vérification de la configuration",
                    "input_label": "Sélectionnez ci-dessous"
                },
                "start_date": {
                    "input_label": "Date de début"
                },
                "status": {
                    "input_label": "Statut"
                },
                "subject_tags": {
                    "example": "par ex. [alerte-gravité][compte][package][domaine]",
                    "input_label": "Activer les préfixes de catégorisation de lignes d'objets"
                }
            },
            "frequency": {
                "description": "Choisissez à quelle fréquence vous souhaitez recevoir cette notification.",
                "disabled-text": "Non disponible - Il s'agit d'une activité basée sur un événement",
                "heading": "Fréquence",
                "options": {
                    "monthly": "Mensuel",
                    "quarterly": "Trimestriel",
                    "six_monthly": "Semestrielle",
                    "two_monthly": "Deux fois par mois",
                    "yearly": "Annuel"
                },
                "starting": "Départ"
            },
            "listing": {
                "table": {
                    "header": {
                        "alert": "Alerte",
                        "frequency": "Fréquence",
                        "recipients": "Destinataires",
                        "status": "Statut"
                    },
                    "record": {
                        "alert_activity": ":scope Activité"
                    }
                },
                "title": "Alertes"
            },
            "monitor": {
                "description": "Sélectionnez l'activité que vous souhaitez surveiller.",
                "heading": "Moniteur"
            },
            "properties": {
                "frequency": {
                    "options": {
                        "daily": "Quotidien",
                        "fortnightly": "Bimensuel",
                        "hourly": "Horaire",
                        "immediate": "Immédiat",
                        "monthly": "Mensuel",
                        "quarterly": "Trimestriel",
                        "six_monthly": "Six mois",
                        "two_monthly": "Deux mois",
                        "weekly": "Hebdomadaire",
                        "yearly": "Annuel"
                    }
                }
            },
            "tags": {
                "status": {
                    "off": {
                        "tooltip": "L'alerte est inactive",
                        "value": "off"
                    },
                    "on": {
                        "tooltip": "L'alerte est active",
                        "value": "on"
                    }
                }
            }
        },
        "bimi_settings": {
            "disable": {
                "action": "Désactiver l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement d'enregistrements BIMI est actuellement activé",
                "message": "Il est recommandé d'activer BIMI Hosting pour gérer facilement votre logo et votre certificat.",
                "modal": {
                    "header": "Désactiver l'hébergement d'enregistrements BIMI pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement d'enregistrements BIMI",
                "heading": "L'hébergement BIMI est désactivé",
                "message": "Il est recommandé que l'hébergement BIMI soit activé pour gérer votre logo et votre certificat.",
                "modal": {
                    "header": "Activer l'hébergement d'enregistrements BIMI :domain",
                    "message": "En activant BIMI Hosting, vous pouvez facilement gérer votre logo et votre certificat."
                }
            },
            "fields": {
                "certificate": {
                    "cn": "Certificat CN",
                    "expiry": "Expiration du certificat",
                    "input_label": "Certificat de marque vérifiée BIMI (Type de fichier : pem)",
                    "url": "Autorité\/URL du certificat"
                },
                "image": {
                    "input_label": "Logo BIMI (Type de fichier : svg)",
                    "preview": "Aperçu du logo",
                    "size": "Taille du logo",
                    "url": "URL du logo"
                }
            },
            "info": {
                "heading": "Qu’est-ce que BIMI ?",
                "message": "BIMI (Brand Indicators for Message Identification) est une norme qui facilite l'affichage de votre logo à côté de vos messages dans la boîte de réception, empêche les e-mails frauduleux et facilite la délivrabilité. BIMI travaille aux côtés de DMARC, DKIM et SPF pour vérifier l'authenticité d'un e-mail et contribuer à la visibilité de la marque."
            },
            "settings": {
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - BIMI"
        },
        "configurations": {
            "actions": {
                "back": "Retour aux paramètres",
                "share": "Partager les instructions de configuration",
                "verify": "Vérifier",
                "view": "Afficher les instructions de configuration"
            },
            "bimi": {
                "description": "Mettez à jour votre DNS pour permettre la configuration des clés publiques BIMI de votre domaine.",
                "modal": {
                    "not_configured": {
                        "header": "Instruction de configuration non disponible",
                        "message": "Afin de générer des enregistrements DNS pour votre configuration BIMI, vous devrez télécharger un logo."
                    }
                }
            },
            "description": "Pour activer la surveillance et la gestion via Sendmarc, veuillez mettre en œuvre les modifications suivantes dans les paramètres DNS de votre domaine.",
            "dkim": {
                "description": "Ajoutez les entrées ci-dessous aux enregistrements DNS de votre domaine pour permettre la configuration de ses clés publiques DKIM.",
                "no_nameservers": "La zone de clé publique DKIM est en cours de création, veuillez attendre les instructions de configuration..."
            },
            "dmarc": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique DMARC de votre domaine et pour capturer des rapports sur l'activité d'authentification des e-mails via notre tableau de bord.",
                "warning": "Prenez note des tirets bas (_) requis dans l'hôte et des points dans la valeur d'enregistrement."
            },
            "existing_dns_record_found": "Enregistrement :record existant trouvé",
            "heading": "Instructions de configuration :title",
            "instructions": {
                "add": "Ajouter un enregistrement :rdType",
                "heading": "Instructions de configuration :rdType",
                "new": "Étape 2 : Ajouter un nouvel enregistrement :rdType",
                "remove": "Étape 1 : Supprimer l'enregistrement :rdType existant"
            },
            "mta_policy": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de la politique MTA de votre domaine.",
                "heading": "Politique MTA-STS"
            },
            "mta_record": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement MTA de votre domaine.",
                "heading": "Enregistrement MTA-STS"
            },
            "record": {
                "host": "Hôte",
                "type": "Type d'enregistrement",
                "value": "Valeur"
            },
            "spf": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement SPF de votre domaine via notre plateforme. Notre plateforme garantit la validité de l'enregistrement et utilise une technologie d'optimisation qui atténue le risque d'atteindre les limites de recherche DNS et garantit que votre politique n'est pas trop permissive."
            },
            "status": {
                "last_date": "Dernière vérification le",
                "pending": "En attente de vérification",
                "verified": "Vérifié"
            },
            "tls_rpt": {
                "description": "Mettez à jour votre DNS pour permettre la configuration de l'enregistrement TLS-RPT de votre domaine et saisir des rapports.",
                "heading": "TLS-RPT"
            }
        },
        "customers": {
            "title": "Gérer les clients"
        },
        "dashboard": {
            "cards": {
                "authorised_timeline": {
                    "heading": "Volume d'e-mails",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé et autre"
                    }
                },
                "domain_totals": {
                    "action": "Afficher les domaines",
                    "enforced": "Appliqué",
                    "heading": "Domaines",
                    "reporting": "Rapports",
                    "total": "Total"
                },
                "sender_totals": {
                    "action": "Afficher les expéditeurs",
                    "heading": "Expéditeurs",
                    "legends": {
                        "approved": "Approuvé",
                        "unapproved": "Non approuvé"
                    }
                }
            },
            "domains": {
                "action": {
                    "view_all_domains": "Afficher tous les domaines"
                },
                "legends": {
                    "failing": "Échec du DMARC",
                    "forwards": "Transferts",
                    "passing": "Passer le DMARC"
                },
                "table": {
                    "action": {
                        "view_report": "Afficher le rapport"
                    },
                    "header": {
                        "approved": "Approuvé",
                        "domain": "Domaines",
                        "senders": "Expéditeurs",
                        "unapproved": "Non approuvé",
                        "volume": "Volume"
                    }
                }
            },
            "senders_compliance": {
                "action": {
                    "view_report": {
                        "tooltip": "Afficher le rapport de domaine"
                    }
                },
                "table": {
                    "header": {
                        "compliance": "Conformité",
                        "domain": "Domaine",
                        "sender": "Expéditeurs approuvés par volume",
                        "volume": "Volume"
                    }
                }
            },
            "title": "Tableau de bord"
        },
        "delegation-verifications": {
            "comparison": {
                "bimi": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    }
                },
                "dkim": {
                    "ns": {
                        "empty": "Aucun serveur de noms correspondant n'a été trouvé.",
                        "mismatch": "Les enregistrements de serveur de noms ne correspondent pas à la configuration attendue.",
                        "missing": "Au moins deux enregistrements de serveur de noms doivent être présents."
                    }
                },
                "dmarc": {
                    "adkim": {
                        "mismatch": "Le mode d'alignement DKIM ne correspond pas à la configuration.",
                        "missing": "Le mode d'alignement DKIM est absent mais n'est pas configuré sur la valeur par défaut."
                    },
                    "aspf": {
                        "mismatch": "Le mode d'alignement SPF ne correspond pas à la configuration.",
                        "missing": "Le mode d'alignement SPF est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "cname": {
                        "mismatch": "L'enregistrement CNAME n'a pas été ajouté correctement et ne correspond pas à la configuration attendue.",
                        "partial": "L'enregistrement CNAME n'a pas été ajouté correctement. Cela peut causer des conflits de configuration."
                    },
                    "fo": {
                        "mismatch": "Les options de rapport d'échec ne correspondent pas à la configuration.",
                        "missing": "Les options de rapport d'échec sont absentes mais ne sont pas configurées avec la valeur par défaut."
                    },
                    "p": {
                        "mismatch": "La politique ne correspond pas à la configuration.",
                        "missing": "La politique est absente."
                    },
                    "pct": {
                        "mismatch": "Le pourcentage de la politique ne correspond pas à la configuration.",
                        "missing": "Le pourcentage de la politique est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "rf": {
                        "mismatch": "Le format de rapport d'échec ne correspond pas à la configuration."
                    },
                    "ri": {
                        "mismatch": "L'intervalle de rapport agrégé ne correspond pas à la configuration.",
                        "missing": "L'intervalle de rapport agrégé est absent mais n'est pas configuré avec la valeur par défaut."
                    },
                    "rua": {
                        "mismatch": "Les destinataires des rapports agrégés ne correspondent pas à la configuration.",
                        "order": "Les destinataires des rapports agrégés ne sont pas dans le bon ordre."
                    },
                    "ruf": {
                        "mismatch": "Les destinataires des rapports d'échec ne correspondent pas à la configuration.",
                        "order": "Les destinataires des rapports d'échec ne sont pas dans le bon ordre."
                    },
                    "sp": {
                        "mismatch": "La politique de sous-domaine ne correspond pas à la configuration.",
                        "missing": "La politique de sous-domaine est absente mais est configurée différemment de la politique de domaine."
                    },
                    "txt": {
                        "reporting-only": "L'enregistrement ne correspond pas à la configuration attendue, mais notre adresse de rapport est présente."
                    }
                },
                "mta-sts": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    }
                },
                "spf": {
                    "record": {
                        "mismatch": "La valeur TXT ne correspond pas à la valeur attendue."
                    }
                },
                "tls-rpt": {
                    "cname": {
                        "mismatch": "La valeur CNAME ne correspond pas à la valeur attendue.",
                        "missing": "Aucun enregistrement CNAME correspondant n'a été trouvé."
                    },
                    "rua": {
                        "missing": "L'adresse de rapport attendue est absente."
                    }
                }
            },
            "verify": {
                "error": "Échec de la vérification",
                "nxdomain": "Enregistrement introuvable.",
                "success": "Vérification terminée avec succès",
                "timeout": "Temps de vérification expiré"
            }
        },
        "dkim_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle clé publique",
                "edit_public_key": {
                    "tooltip": "Modifier la clé publique"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle clé publique"
            },
            "disable": {
                "action": "Gestion DKIM désactivée",
                "heading": "L'hébergement par clé publique DKIM est actuellement activé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Désactiver la gestion DKIM pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "edit_drawer": {
                "header": "Modifier la clé publique"
            },
            "enable": {
                "action": "Activer la gestion DKIM",
                "heading": "L'hébergement de clé publique DKIM est désactivé",
                "message": "Il est recommandé d'activer la gestion DKIM pour vous aider à gérer vos clés publiques.",
                "modal": {
                    "header": "Activer la gestion DKIM pour :domain",
                    "message": "En activant la gestion DKIM, vous pouvez gérer facilement vos clés publiques."
                }
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "record_type": {
                    "input_label": "Type d'enregistrement"
                },
                "record_value": {
                    "example": "par ex. dkim.exemple.com",
                    "input_label": "Enregistrer la valeur"
                },
                "selector": {
                    "input_label": "Sélecteur"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Importation de la clé publique DKIM terminée"
                },
                "disabled_tooltip": "Les clés publiques DKIM ne peuvent être importées que pour les domaines disposant de rapports.",
                "header": "Réimporter les clés publiques DKIM pour :domain",
                "message": "Toutes les clés publiques existantes seront supprimées et remplacées par des directives importées."
            },
            "info": {
                "heading": "Qu’est-ce que le DKIM ?",
                "message": "Domain Keys Identified Mail est une méthode d'authentification d'e-mail qui permet à un expéditeur de signer numériquement un e-mail à l'aide d'une clé privée associée à son nom de domaine, qui peut être vérifiée par le serveur d'e-mail du destinataire en utilisant une clé publique publiée dans les enregistrements DNS de l'expéditeur. Cela permet de vérifier l'authenticité et l'intégrité de l'e-mail, réduisant ainsi le risque d'attaques de spam et de phishing."
            },
            "policy_settings": {
                "fields": {
                    "policy": {
                        "input_label": "Politique de signature sortante",
                        "options": {
                            "all": "Tous les e-mails",
                            "some": "Quelques e-mails"
                        }
                    },
                    "testing": {
                        "input_label": "Mode test"
                    }
                },
                "heading": "Paramètres de politiques"
            },
            "public_keys": {
                "table": {
                    "header": {
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "value": "Valeur"
                    }
                }
            },
            "title": "Modifier les paramètres – DKIM"
        },
        "dmarc_settings": {
            "alignment": {
                "fields": {
                    "dkim_mode": {
                        "input_label": "Mode d'alignement DKIM"
                    },
                    "options": {
                        "r": "Détendu",
                        "s": "Strict"
                    },
                    "spf_mode": {
                        "input_label": "Mode d'alignement SPF"
                    }
                },
                "heading": "Paramètres d'alignement"
            },
            "policy": {
                "fields": {
                    "diff_subdomain_policy": {
                        "input_label": "Appliquer une politique de sous-domaine différente"
                    },
                    "percentage": {
                        "input_label": "Pourcentage"
                    },
                    "policy": {
                        "input_label": "Politique DMARC"
                    },
                    "subdomain_policy": {
                        "input_label": "Politique de sous-domaine"
                    }
                },
                "heading": "Paramètres de politiques"
            },
            "rua": {
                "fields": {
                    "emails": {
                        "input_label": "Les rapports seront envoyés à"
                    },
                    "interval": {
                        "input_label": "Intervalle de rapport",
                        "options": {
                            "1_day": "Une fois par jour",
                            "1_hour": "Toutes les heures",
                            "2_hours": "Toutes les 2 heures",
                            "4_hours": "Toutes les 4 heures",
                            "6_hours": "Toutes les 6 heures"
                        }
                    }
                },
                "heading": "Rapports agrégés"
            },
            "ruf": {
                "confirm_modal": {
                    "accept": "Activer",
                    "header": "Activer le rapport d'échec",
                    "message": "En activant le rapport d'échec, certaines informations personnelles, telles que les adresses e-mail ou le contenu des messages, peuvent être exposées à des fins de diagnostic.",
                    "reject": "Désactiver"
                },
                "fields": {
                    "options": {
                        "input_label": "Options de rapport",
                        "options": {
                            "d": "DKIM échoue quel que soit l'alignement",
                            "s": "SPF échoue quel que soit l’alignement",
                            "0": "SPF ou DKIM échouent et ne s'alignent pas",
                            "1": "SPF et DKIM échouent et ne s'alignent pas"
                        }
                    },
                    "status": {
                        "input_label": "Traitement des rapports",
                        "options": {
                            "disabled": "Désactivé",
                            "enabled": "Activé",
                            "specified": "Destinataires spécifiques"
                        }
                    }
                },
                "heading": "Rapports d'échec"
            },
            "title": "Modifier les paramètres - DMARC"
        },
        "domains": {
            "actions": {
                "compliance_certificate": "Certificat de conformité DMARC",
                "domain_report": "Rapport de domaine",
                "edit_settings": {
                    "tooltip": "Modifier les paramètres"
                },
                "remove_primary": "Supprimer des favoris",
                "set_as_active": "Définir comme actif",
                "set_as_parked": "Définir comme parqué",
                "set_primary": "Marquer comme favori",
                "view_logs": "Afficher les journaux"
            },
            "create_drawer": {
                "header": "Ajouter un nouveau domaine"
            },
            "fields": {
                "name": {
                    "example": "par ex. \"exemple.com\" ou \"sous-domaine.exemple.com\"",
                    "input_label": "Nom de domaine"
                },
                "type": {
                    "input_label": "Type de domaine",
                    "options": {
                        "active": "Actif",
                        "parked": "Parqué"
                    }
                }
            },
            "listing": {
                "table": {
                    "header": {
                        "name": "Domaine",
                        "policy": "Politique",
                        "score": "Score",
                        "status": "Statut",
                        "type": "Type"
                    }
                },
                "title": "Domaines"
            },
            "logs_drawer": {
                "header": "Domaine"
            },
            "report_modal": {
                "action": "Générer un lien",
                "fields": {
                    "copy_link": {
                        "input_label": "Copiez le lien ci-dessous pour partager le rapport de domaine"
                    },
                    "date_range": {
                        "input_label": "Intervalle de dates"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Rapport de domaine"
            },
            "settings": {
                "title": "Modifier les paramètres"
            },
            "setup_modal": {
                "action": "Générer un lien",
                "fields": {
                    "copy_link": {
                        "input_label": "Copiez le lien ci-dessous pour partager les instructions de configuration"
                    },
                    "expire_link": {
                        "input_label": "Expirer le lien dans",
                        "options": {
                            "1_day": "Un jour",
                            "1_month": "Un mois",
                            "1_week": "Une semaine",
                            "6_hours": "6 heures",
                            "12_hours": "12 heures",
                            "never": "Jamais"
                        }
                    }
                },
                "header": "Lien vers les instructions de configuration"
            },
            "tags": {
                "policy": {
                    "none": {
                        "value": "Aucun"
                    },
                    "quarantine": {
                        "value": "Quarantaine"
                    },
                    "reject": {
                        "value": "Rejeter"
                    },
                    "tooltip": "Politique appliquée"
                },
                "primary": {
                    "tooltip": "Préféré",
                    "value": "Préféré"
                },
                "score": {
                    "high": {
                        "tooltip": "Risque élevé"
                    },
                    "low": {
                        "tooltip": "Risque faible"
                    },
                    "moderate": {
                        "tooltip": "Risque modéré"
                    }
                },
                "status": {
                    "not_reporting": {
                        "tooltip": "Vérifié, pas de rapport",
                        "tooltip_partial": "Partiellement vérifié, pas de rapport",
                        "value": "Pas de rapport"
                    },
                    "not_verified": {
                        "tooltip": "Non vérifié, pas de rapport",
                        "value": "Non vérifié"
                    },
                    "reporting": {
                        "tooltip": "Vérifié, rapport",
                        "tooltip_partial": "Partiellement vérifié, rapport",
                        "value": "Rapports"
                    }
                },
                "type": {
                    "active": {
                        "tooltip": "Domaine actif",
                        "value": "Actif"
                    },
                    "parked": {
                        "tooltip": "Domaine parqué",
                        "value": "Parqué"
                    }
                }
            }
        },
        "email_list": {
            "fields": {
                "email": {
                    "example": "par ex. destinataire@exemple.com",
                    "input_label": "Modifier l'e-mail",
                    "placeholder": "Entrez l'adresse e-mail",
                    "subscribed_label": "Abonné",
                    "unsubscribed_label": "Désabonné",
                    "validation_message": "Entrez une adresse e-mail valide"
                }
            }
        },
        "errors": {
            "menu": "Erreur",
            "401": {
                "description": "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
                "title": "401 : Non autorisé"
            },
            "403": {
                "description": "Désolé, il vous est interdit d'accéder à cette page.",
                "title": "403 : Interdit"
            },
            "404": {
                "description": "Désolé, la page que vous recherchez est introuvable.",
                "title": "404 : page introuvable"
            },
            "500": {
                "description": "Oups, quelque chose s'est mal passé sur nos serveurs.",
                "title": "500 : erreur du serveur"
            },
            "502": {
                "description": "Désolé, nos serveurs sont en panne.",
                "title": "502 : Mauvaise passerelle"
            },
            "503": {
                "description": "Désolé, nous effectuons des travaux de maintenance. Veuillez revenir bientôt.",
                "title": "503 : Service indisponible"
            }
        },
        "exposure_report": {
            "email_volume": {
                "heading": "Volume d'e-mails",
                "legends": {
                    "failing": "Échec du DMARC",
                    "forward": "Transferts",
                    "passing": "Succès du DMARC"
                }
            },
            "heading": "Rapport de domaine",
            "recommendations": {
                "heading": "Recommandations"
            },
            "risk_analysis": {
                "heading": "Analyse des risques"
            },
            "scoring": {
                "note": "Remarque : Le score du domaine est calculé au moment de la génération du rapport et peut ne pas être à jour au moment de la consultation."
            },
            "sender_readiness": {
                "heading": "Préparation DMARC de l'expéditeur",
                "legends": {
                    "configured": {
                        "description": "La préparation DMARC est une mesure de la configuration requise pour mettre en œuvre une conformité totale.",
                        "heading": "Configuré"
                    },
                    "partially": {
                        "description": "Les sources partiellement configurées ont au moins un mécanisme d'autorisation actif, mais une configuration supplémentaire peut être nécessaire.",
                        "heading": "Partiellement configuré"
                    },
                    "unconfigured": {
                        "description": "Les sources illégitimes ou non configurées sont des sources qui envoient des e-mails à partir de votre domaine qui peuvent usurper l'identité de votre marque.",
                        "heading": "Illégitime ou non configuré"
                    }
                }
            },
            "title": "Rapport de domaine pour :domain",
            "top_countries": {
                "heading": "Principaux pays d'origine – Volume d'e-mails"
            },
            "top_senders": {
                "heading": "Principaux expéditeurs – Conformité"
            }
        },
        "failure_reports": {
            "detail_drawer": {
                "action": "Télécharger le rapport",
                "header": "Rapport d'échec",
                "menu": {
                    "key_properties": "Propriétés clés",
                    "more_info": "Plus d'informations"
                }
            },
            "properties": {
                "authentication_failure": "Échec d'authentification",
                "date": "Date",
                "delivery_results": "Résultats de livraison",
                "dkim_domain": "Domaine DKIM",
                "dkim_header": "En-tête canonique DKIM",
                "dkim_identity": "Identité DKIM",
                "dkim_selector": "Sélecteur DKIM",
                "dmarc_version": "Version DMARC",
                "feedback_type": "Type de commentaires",
                "incidents": "Nombre d'incidents",
                "original_recipients": "Destinataires originaux",
                "reporting_domains": "Domaines signalés",
                "reporting_mta": "MTA de rapporting",
                "reporting_user_agent": "Agent utilisateur de reporting",
                "return_path": "Chemin de retour",
                "source_ip": "Adresse IP source",
                "spf_dns": "DNS SPF"
            },
            "table": {
                "header": {
                    "authentication": "Authentification",
                    "date": "Date",
                    "ip_address": "Adresse IP",
                    "result": "Résultat",
                    "return_path": "Chemin de retour"
                }
            },
            "tags": {
                "result": {
                    "delivered": "Livré",
                    "none": "Aucun, accepter",
                    "smg_policy_action": "Politique appliquée",
                    "spam": "Rejeté",
                    "unknown": "Inconnu"
                }
            },
            "title": "Rapports d'échec pour :domain"
        },
        "global": {
            "buttons": {
                "add": "Ajouter",
                "add_new": "Ajouter un nouveau",
                "back": "Dos",
                "cancel": "Annuler",
                "delete": "Supprimer",
                "import": "Importer",
                "next": "Suivant",
                "no": "Non",
                "open_link": "Ouvrir le lien",
                "remove": "Retirer",
                "save": "Enregistrer",
                "save_exit": "Enregistrer et retour",
                "select": "Sélectionner",
                "yes": "Oui"
            },
            "confirm_delete": "Voulez-vous continuer ?",
            "copied": "Copié",
            "created": "Créé",
            "deleted": "Supprimé",
            "fail": "Échec",
            "fields": {
                "enabled_disabled": {
                    "options": {
                        "disabled": "Désactivé",
                        "enabled": "Activé"
                    }
                },
                "on_off": {
                    "options": {
                        "off": "Off",
                        "on": "On"
                    }
                },
                "yes_no": {
                    "options": {
                        "no": "Non",
                        "yes": "Oui"
                    }
                }
            },
            "form": {
                "failed": {
                    "description": "Erreur : Veuillez remplir correctement tous les champs obligatoires.",
                    "title": "Il y a eu des erreurs avec votre formulaire."
                },
                "success": {
                    "title": "Vos modifications ont été enregistrées."
                }
            },
            "graphs": {
                "legends": {
                    "volume": "Volume"
                }
            },
            "logout": "Déconnexion",
            "not_found": "Pas trouvé",
            "pagination": {
                "of": "de",
                "showing": "Affichage",
                "to": "à"
            },
            "pass": "Passer",
            "remove": "Retirer",
            "show_less": "Afficher moins",
            "show_more": "Afficher plus",
            "system": "Système",
            "theme": {
                "dark": "Sombre",
                "light": "Clair"
            },
            "to": "À"
        },
        "ip_address_modal": {
            "header": {
                "date_range": "Intervalle de dates",
                "to": "à"
            },
            "menu": {
                "overrides": "Remplacements",
                "overview": "Vue d'ensemble",
                "reporters": "Raporteurs",
                "results": "Résultats"
            },
            "overrides": {
                "legends": {
                    "policy_applied_none": "Politique appliquée \"Aucune\"",
                    "policy_applied_quarantine": "Politique appliquée \"Quarantaine\"",
                    "policy_applied_reject": "Politique appliquée \"Rejeter\""
                },
                "overridden_policy_breakdown": "Répartition de la politique remplacée",
                "volume_overridden_policy": "Volume avec des politiques remplacées"
            },
            "overview": {
                "cards": {
                    "compliance": "Conformité",
                    "deliverability": "Délivrabilité",
                    "volume": "Volume"
                },
                "properties": {
                    "country": "Pays",
                    "host": "HÔTE",
                    "isp": "FAI",
                    "organisation": "Organisation",
                    "sender": "Expéditeur"
                }
            },
            "reporters": {
                "table": {
                    "header": {
                        "compliance": "Conformité",
                        "envelope_from": "Enveloppe De",
                        "envelope_to": "Enveloppe à",
                        "header_from": "En-tête de",
                        "no_data": "Aucune donnée de raporteur disponible",
                        "percent_of_total": "% du total",
                        "reporter": "Raporteur",
                        "volume": "Volume"
                    }
                }
            },
            "results": {
                "legends": {
                    "align": "Alignement",
                    "error": "Erreur",
                    "fail": "Échec",
                    "failed": "Échec\/non aligné",
                    "none": "Aucun",
                    "pass": "Succès",
                    "passed": "Succès\/Aligné"
                }
            },
            "table": {
                "action_applied": {
                    "header": "Action appliquée",
                    "info": {
                        "tooltip": "Mesure prise par le destinataire"
                    },
                    "tags": {
                        "delivered": {
                            "tooltip": "Livré",
                            "value": "Livré"
                        },
                        "quarantine": {
                            "tooltip": "Mis en quarantaine",
                            "value": "Mis en quarantaine"
                        },
                        "reject": {
                            "tooltip": "Rejeté",
                            "value": "Rejeté"
                        }
                    }
                },
                "dkim": {
                    "details": {
                        "header": {
                            "alignment": "Alignement",
                            "dmarc_via_dkim": "DMARC via DKIM",
                            "results": "Résultats",
                            "selector": "Sélecteur",
                            "signing_domain": "Domaine de signature"
                        },
                        "heading": "Détails sur DKIM"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "DKIM non aligné",
                            "no_info": "Aucune information sur DKIM",
                            "yes": "Aligné sur DKIM"
                        },
                        "header": "Résultats du DKIM",
                        "no_details": "Aucun détail de signature DKIM",
                        "pass_tooltips": {
                            "no": "Échec du DKIM",
                            "none": "Aucun DKIM",
                            "yes": "Succès du DKIM"
                        }
                    }
                },
                "dmarc": {
                    "details": {
                        "description": {
                            "action_applied": "La politique :policy a été appliquée par le destinataire"
                        },
                        "header": {
                            "from_domain": "Du domaine"
                        },
                        "heading": "Autres détails"
                    },
                    "results": {
                        "header": "Résultats du DMARC",
                        "pass_tooltips": {
                            "no": "Échec du DMARC",
                            "yes": "Succès du DMARC"
                        }
                    }
                },
                "override_comment": {
                    "header": "Commentaire"
                },
                "override_type": {
                    "header": "Type de remplacement"
                },
                "published_policy": {
                    "header": "Politique publiée",
                    "info": {
                        "tooltip": "Politique DMARC au moment de la validation"
                    }
                },
                "reporter": {
                    "header": "Raporteur"
                },
                "spf": {
                    "details": {
                        "header": {
                            "alignment": "Alignement",
                            "dmarc_via_spf": "DMARC via SPF",
                            "result": "Résultat",
                            "return_path_domain": "Domaine du chemin de retour"
                        },
                        "heading": "Détails sur SPF"
                    },
                    "results": {
                        "alignment_tooltips": {
                            "no": "SPF non aligné",
                            "no_info": "Aucune information sur SPF",
                            "yes": "Aligné sur SPF"
                        },
                        "header": "Résultats du SPF",
                        "no_details": "Aucun détail sur SPF",
                        "pass_tooltips": {
                            "error": "Erreur temporaire du SPF",
                            "no": "Échec du SPF",
                            "none": "Aucun FPS",
                            "yes": "Succès du SPF"
                        }
                    }
                },
                "volume": {
                    "header": "Volume"
                }
            }
        },
        "logs": {
            "events": {
                "created": "créé",
                "deleted": "supprimé",
                "restored": "restauré",
                "updated": "mis à jour"
            },
            "formatters": {
                "bimi_settings": {
                    "attributes": {
                        "certificate_path": "Certificat",
                        "image_path": "Logo",
                        "is_delegation_enabled": "Délégation activée",
                        "nameservers": "Serveurs de noms",
                        "selector": "Sélecteur"
                    },
                    "title": "Paramètres BIMI :event"
                },
                "default": {
                    "title": ":title :event"
                },
                "dkim_public_keys": {
                    "attributes": {
                        "description": "Description",
                        "record_type": "Type d'enregistrement",
                        "selector": "Sélecteur",
                        "status": "Statut",
                        "ttl": "TTL",
                        "value": "Valeur"
                    },
                    "title": "Clé publique DKIM :event"
                },
                "dkim_settings": {
                    "attributes": {
                        "is_delegation_enabled": "Délégation activée",
                        "is_policy_enabled": "Politique activée",
                        "nameservers": "Serveurs de noms",
                        "policy": "Politique"
                    },
                    "title": "Paramètres DKIM :event"
                },
                "dmarc_settings": {
                    "attributes": {
                        "dkim_alignment": "Alignement DKIM",
                        "policy": "Politique",
                        "policy_percentage": "Pourcentage de politique",
                        "rua_emails": "Destinataires du rapport global",
                        "rua_interval": "Intervalle de rapport global",
                        "ruf_emails": "Destinataires du rapport d'échec",
                        "ruf_format": "Format du rapport d'échec",
                        "ruf_options": "Options de rapport d'échec",
                        "ruf_status": "État du rapport d'échec",
                        "spf_alignment": "Alignement SPF",
                        "subdomain_policy": "Politique de sous-domaine"
                    },
                    "title": "Paramètres DMARC :event"
                },
                "dns_delegation_verification": {
                    "attributes": {
                        "messages": "Messages",
                        "result": "Résultat",
                        "started_at": "Commencé à",
                        "status": "Statut",
                        "type": "Taper"
                    },
                    "title": "Vérification de la délégation DNS :event"
                },
                "domain": {
                    "attributes": {
                        "domain_name": "Domaine",
                        "is_favorite": "Préféré",
                        "logo": "Logo",
                        "score": "Score de domaine",
                        "type": "Type"
                    },
                    "title": "Domaine :event"
                },
                "mta_settings": {
                    "attributes": {
                        "is_record_enabled": "Hébergement d'enregistrement activé",
                        "mx_records": "Enregistrements MX",
                        "policy": "Mode politique"
                    },
                    "title": "Paramètres MTA-STS :event"
                },
                "spf_directives": {
                    "attributes": {
                        "description": "Description",
                        "mechanism": "Type d'enregistrement",
                        "order": "Ordre",
                        "qualifier": "Qualificateur d'enregistrement",
                        "status": "Statut",
                        "value": "Valeur de l'hôte"
                    },
                    "title": "Directives SPF :event"
                },
                "spf_settings": {
                    "attributes": {
                        "all_mechanism_qualifier": "Qualificateur de record de haut niveau",
                        "enable_flattening": "Aplatissement activé"
                    },
                    "title": "Paramètres SPF :event"
                },
                "tls_settings": {
                    "attributes": {
                        "enable_tls_rpt": "TLS-RPT activé",
                        "rua_emails": "Signaler des e-mails"
                    },
                    "title": "Paramètres TLS-RPT :event"
                }
            },
            "header": ":type Journaux"
        },
        "menu": {
            "account": "Compte",
            "domains": "Domaines",
            "home": "Tableau de bord",
            "reports": {
                "email_senders": "Expéditeurs d'e-mails",
                "failure_reports": "Rapports d'échec",
                "reports": "Rapports",
                "tls_rpt_reports": "Rapports TLS-RPT"
            },
            "settings": {
                "alerts": "Alertes",
                "logout": "Déconnexion",
                "settings": "Paramètres"
            }
        },
        "mta_settings": {
            "disable": {
                "action": "Hébergement MTA-STS désactivé",
                "heading": "L'hébergement MTA-STS est activé",
                "message": "Il est recommandé d'activer la création de rapports TLS-RPT et l'hébergement de politiques MTA-STS.",
                "modal": {
                    "header": "Désactiver l'hébergement MTA-STS pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer l'hébergement MTA-STS",
                "heading": "L'hébergement MTA-STS est désactivé",
                "message": "Il est recommandé que l’hébergement des enregistrements et des politiques MTA-STS soit activé pour gérer efficacement vos enregistrements.",
                "modal": {
                    "header": "Activer l'hébergement MTA-STS pour :domain",
                    "message": "En activant l'hébergement MTA-STS, vous pouvez gérer votre dossier et l'hébergement de votre police."
                }
            },
            "fields": {
                "mx_servers": {
                    "input_label": "Serveurs MX"
                },
                "policy": {
                    "input_label": "Niveau d'application des politiques",
                    "options": {
                        "enforce": "Appliqué",
                        "none": "Aucun",
                        "testing": "En test"
                    }
                }
            },
            "heading": "Hébergement des enregistrements et des politiques MTA-STS",
            "info": {
                "heading": "Qu'est-ce que TLS-RPT et MTA-STS ?",
                "message": "TLS-RPT ou SMTP TLS Reporting (Transport Layer Security Reporting) permet aux systèmes d'envoi de partager des statistiques et des informations sur les échecs avec les domaines destinataires. MTA-STS (Mail Transfer Agents - Strict Transport Security) permet aux fournisseurs de services de messagerie de forcer à accepter uniquement les connexions SMTP entrantes sécurisées via TLS."
            },
            "status": {
                "policy": {
                    "pending": "Politique en attente de vérification",
                    "verified": "Politique vérifiée"
                },
                "record": {
                    "pending": "Enregistrement en attente de vérification",
                    "verified": "Enregistrement vérifié"
                }
            }
        },
        "partners": {
            "title": "Gérer les partenaires"
        },
        "reports": {
            "cards": {
                "domain_not_verified": {
                    "actions": {
                        "configure": "Configurer les paramètres DMARC",
                        "manage_domains": "Gérer les domaines"
                    },
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports ne peuvent être récupérés que pour les domaines actifs et vérifiés. Gérez vos domaines dans le module Domaines.",
                    "header": "Aucune donnée de rapport disponible"
                },
                "no_reporting_data": {
                    "description": "Aucune donnée de rapport disponible pour l'intervalle de dates sélectionné. Veuillez ajuster votre sélection et réessayer.",
                    "header": "Aucune donnée de rapport disponible"
                }
            },
            "senders": {
                "unknown": "Inconnu"
            }
        },
        "scoring": {
            "blurb": {
                "high": "Vous ne disposez pas de contrôles efficaces pour protéger votre domaine contre l'usurpation d’identité et l'interception de communications par e-mail. Cela met en péril votre image de marque, augment les risques d'attaque des destinataires de vos e-mails, réduit la confiance et peut nuire à la délivrabilité des e-mails.",
                "low": "El correo electrónico de su dominio está altamente protegido contra la suplantación e interceptación",
                "moderate": "Vous avez mis en place certaines mesures pour protéger les destinataires contre les e-mails malveillants de votre domaine. Il convient de prêter attention aux domaines dans lesquels des contrôles peuvent être améliorés. Ces mesures renforcent la confiance en votre marque, protègent les destinataires contre les attaques et contribuent à garantir la transmission efficace des e-mails."
            },
            "header": "Score de domaine",
            "impersonation": "Imitation",
            "marketing": "Marketing",
            "note": "REMARQUE : Il s'agit du score actuel de votre domaine et peut ne pas refléter les modifications que vous avez apportées aujourd'hui.",
            "privacy": "Confidentialité",
            "rating": {
                "high": "Risque élevé",
                "low": "Risque faible",
                "moderate": "Risque modéré"
            }
        },
        "senders_reports": {
            "cards": {
                "blocked_volume": "Volume bloqué",
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "deliverable": "Livrable",
                "deliverable_volume": "Volume livrable",
                "ip_addresses": "Adresses IP",
                "senders": "Expéditeurs",
                "volume": "Volume d'e-mails"
            },
            "categories": {
                "all": "Tous les expéditeurs",
                "authorised_compliant": "Approuvé",
                "forwarder": "Transitaire",
                "suspicious": "Suspect",
                "unauthorised": "Non approuvé",
                "unknown": "Aucun"
            },
            "menu": {
                "compliance": "Conformité",
                "deliverability": "Délivrabilité",
                "reputation": "Réputation",
                "threats": "Menaces"
            },
            "messages": {
                "category_updated": {
                    "error": "Échec de la mise à jour de la catégorie d'expéditeur",
                    "success": "Catégorie d'expéditeur mise à jour"
                }
            },
            "senders": {
                "legends": {
                    "blacklisted": "Sur liste noire",
                    "clean": "Nettoyer",
                    "compliant": "Conforme",
                    "delivered": "Livré",
                    "failing_dmarc": "Échec du DMARC",
                    "forward": "Transferts",
                    "not_delivered": "Non livré",
                    "overridden": "Remplacé",
                    "overridden_as_delivered": "Remplacé comme Livré",
                    "overridden_as_not_delivered": "Remplacé comme Non livré",
                    "passing": "Valider",
                    "passing_dkim": "Valider le DKIM uniquement",
                    "passing_dkim_spf": "Valider le DKIM et SPF",
                    "passing_dmarc": "Valider le DMARC",
                    "passing_spf": "Valider le SPF uniquement",
                    "senders": "Expéditeurs"
                },
                "table": {
                    "header": {
                        "approved_sender_ips": "Adresses IP approuvées d'expéditeurs",
                        "approved_sender_volume": "Volume des expéditeurs approuvés",
                        "blacklisted": "Sur liste noire",
                        "blacklists": "Listes noires",
                        "blocked_volume": "Volume bloqué",
                        "category": "Catégorie",
                        "clean": "Nettoyer",
                        "compliance": "Conformité",
                        "country": "Pays",
                        "deliverability": "Délivrabilité",
                        "delivered": "Livré",
                        "failing": "En échec",
                        "forwards": "Transferts",
                        "host": "Hôte",
                        "ip": "IP",
                        "ip_addresses": "Adresses IP",
                        "not_delivered": "Non livré",
                        "passing": "Passage",
                        "sender": "Expéditeur",
                        "top_blacklisted_senders": "Principaux expéditeurs sur liste noire",
                        "volume": "Volume"
                    },
                    "info": {
                        "blacklists": "Nombre de listes noires dans lesquelles l'adresse IP a été répertoriée"
                    }
                }
            },
            "title": "Expéditeurs d'e-mails pour :domain"
        },
        "spf_settings": {
            "actions": {
                "add_new": "Ajouter une nouvelle directive SPF",
                "edit_record": {
                    "tooltip": "Modifier l'enregistrement"
                }
            },
            "create_drawer": {
                "header": "Ajouter une nouvelle directive SPF"
            },
            "directives": {
                "table": {
                    "header": {
                        "directives": "Directives",
                        "order": "Ordre"
                    }
                },
                "titles": {
                    "allow_a_none": "Autoriser les enregistrements A pour votre propre domaine",
                    "allow_a_value": "Autoriser les enregistrements A pour :value",
                    "allow_exists": "Autoriser le macro existant :value",
                    "allow_include": "Inclure :value",
                    "allow_ip4_address": "Inclure l'adresse IPv4 :value",
                    "allow_ip4_addresses": "Inclure les adresses IPv4 dans :value",
                    "allow_ip6_address": "Inclure l'adresse IPv6 :value",
                    "allow_ip6_addresses": "Inclure les adresses IPv6 dans :value",
                    "allow_mx_none": "Autoriser les enregistrements MX pour votre propre domaine",
                    "allow_mx_value": "Autoriser les enregistrements MX pour :value",
                    "fail_a_none": "Échec des enregistrements A pour votre propre domaine",
                    "fail_a_value": "Échec des enregistrements A pour :value",
                    "fail_exists": "Échec du macro existant :value",
                    "fail_include": "Échec d'inclusion de :value",
                    "fail_ip4_address": "Échec de l'adresse IPv4 :value",
                    "fail_ip4_addresses": "Échec des adresses IPv4 dans :value",
                    "fail_ip6_address": "Échec de l'adresse IPv6 :value",
                    "fail_ip6_addresses": "Échec des adresses IPv6 dans :value",
                    "fail_mx_none": "Échec des enregistrements MX pour votre propre domaine",
                    "fail_mx_value": "Échec des enregistrements MX pour :value",
                    "neutral_a_none": "Être neutre sur les enregistrements A pour votre propre domaine",
                    "neutral_a_value": "Être neutre sur les enregistrements A pour :value",
                    "neutral_exists": "Être neutre sur le macro existant :value",
                    "neutral_include": "Être neutre sur l'inclusion de :value",
                    "neutral_ip4_address": "Être neutre sur l'adresse IPv4 :value",
                    "neutral_ip4_addresses": "Être neutre sur les adresses IPv4 dans :value",
                    "neutral_ip6_address": "Être neutre sur l'adresse IPv6 :value",
                    "neutral_ip6_addresses": "Être neutre sur les adresses IPv6 dans :value",
                    "neutral_mx_none": "Être neutre sur les enregistrements MX pour votre propre domaine",
                    "neutral_mx_value": "Être neutre sur les enregistrements MX pour :value",
                    "soft_fail_a_none": "Échec léger des enregistrements A pour votre propre domaine",
                    "soft_fail_a_value": "Échec léger des enregistrements A pour :value",
                    "soft_fail_exists": "Échec léger du macro existant :value",
                    "soft_fail_include": "Échec léger d'inclusion de :value",
                    "soft_fail_ip4_address": "Échec léger de l'adresse IPv4 :value",
                    "soft_fail_ip4_addresses": "Échec léger des adresses IPv4 dans :value",
                    "soft_fail_ip6_address": "Échec léger de l'adresse IPv6 :value",
                    "soft_fail_ip6_addresses": "Échec léger des adresses IPv6 dans :value",
                    "soft_fail_mx_none": "Échec léger des enregistrements MX pour votre propre domaine",
                    "soft_fail_mx_value": "Échec léger des enregistrements MX pour :value"
                }
            },
            "edit_drawer": {
                "header": "Modifier la directive SPF"
            },
            "fields": {
                "description": {
                    "input_label": "Description"
                },
                "host": {
                    "example": "par ex. _spf.exemple.com",
                    "input_label": "Valeur :type"
                },
                "mechanism": {
                    "input_label": "Type d'enregistrement"
                },
                "qualifier": {
                    "input_label": "Qualificateur d'enregistrement",
                    "options": {
                        "allow": "+ Allow",
                        "fail": "- Fail",
                        "neutral": "? Neutral (non recommandé)",
                        "soft_fail": "~ Soft Fail"
                    }
                },
                "update": "Mettre à jour une directive Spf",
                "use_own_domain": {
                    "input_label": "Domaine du mécanisme"
                }
            },
            "import_modal": {
                "action": "Supprimer et importer",
                "complete": {
                    "message": "Import SPF terminé"
                },
                "header": "Importer l'enregistrement et les directives SPF existants pour :domain",
                "message": "Toutes les directives existantes seront supprimées et remplacées par des directives importées."
            },
            "settings": {
                "fields": {
                    "all_mechanism_qualifier": {
                        "input_label": "Qualificateur de journal de haut niveau",
                        "options": {
                            "allow": "+ Pass (non recommandé)",
                            "fail": "- Fail",
                            "neutral": "? Neutral (non recommandé)",
                            "soft_fail": "~ Soft Fail"
                        },
                        "options_tooltips": {
                            "+": "+tout Régler sur valider l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "-": "Régler sur faire échouer l’authentification des e-mails provenant d’expéditeurs non autorisés.",
                            "?": "Régler sur neutre, qui ne valide ni n'échoue l'authentification des e-mails provenant d'expéditeurs non autorisés.",
                            "~": "Régler sur provoquer un échec logiciel pour l'authentification des e-mails provenant d'expéditeurs non autorisés et les traiter comme suspects."
                        }
                    },
                    "flattening": {
                        "input_label": "Activer l'aplatissement"
                    }
                },
                "heading": "Paramètres"
            },
            "title": "Modifier les paramètres - SPF"
        },
        "tables": {
            "clear": "Effacer",
            "not_found": "Aucun enregistrement correspondant trouvé",
            "search": "Recherche"
        },
        "tls_reports": {
            "cards": {
                "not_configured": {
                    "action": "Configurer les paramètres TLS-RPT",
                    "description": "Nous n'avons pas encore reçu de données de rapport pour votre domaine. Cela peut prendre jusqu'à 72 heures pour que les données apparaissent. Les rapports TLS-RPT ne peuvent être récupérés que pour les domaines pour lesquels les rapports TLS-RPT sont activés.",
                    "header": "Aucune donnée de rapport disponible"
                }
            },
            "heading": "Rapports TLS-RPT pour :domain",
            "reporters": {
                "heading": "Rapports",
                "legend": {
                    "reports": "Rapports"
                },
                "table": {
                    "header": {
                        "policy_mode": "Mode politique",
                        "policy_type": "Type de politique",
                        "reporter": "Rapporteur",
                        "total": "Total"
                    }
                }
            },
            "timeline": {
                "heading": "Chronologie",
                "legend": {
                    "failure": "Échoué",
                    "success": "Réussi"
                }
            },
            "title": "Rapports TLS-RPT"
        },
        "tls_settings": {
            "disable": {
                "action": "Désactiver les rapports TLS-RPT",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Rapports TLS-RPT désactivés pour :domain",
                    "message": "Tous les paramètres et configurations de ce domaine seront supprimés, cette action ne peut pas être annulée."
                }
            },
            "enable": {
                "action": "Activer les rapports TLS-RPT",
                "heading": "La création de rapports TLS-RPT est désactivée",
                "message": "Il est recommandé d'activer les rapports TLS-RPT pour surveiller les rapports.",
                "modal": {
                    "header": "Activer la création de rapports TLS-RPT pour :domain",
                    "message": "En activant les rapports TLS-RPT, vous pouvez surveiller les statistiques et les informations sur les échecs avec les domaines destinataires."
                }
            },
            "fields": {
                "email": {
                    "input_label": "Les rapports seront envoyés à"
                }
            },
            "heading": "Rapports TLS-RPT",
            "title": "Modifier les paramètres - TLS-RPT"
        },
        "upload": {
            "add": "Ajouter des fichiers",
            "drop_zone": "Glissez-déposez un fichier ici pour l'envoyer.",
            "title": "Envoyer"
        },
        "users": {
            "email": {
                "edit": "Modifier l'e-mail",
                "placeholder": "Entrez l'adresse e-mail"
            },
            "menu": "Utilisateurs",
            "name": {
                "title": "Nom"
            },
            "title": "Gérer les utilisateurs"
        },
        "validation": {
            "email": "Pas une adresse e-mail valide",
            "invalid_attr": "Le :attribute n'est pas valide."
        }
    }
} as const;