import { type Reactive, watch } from 'vue';
import type { Page } from '@inertiajs/core';
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { useBrowserLanguages } from '@/Utils/Translations/useBrowserLanguages';

export const setupAxios = (axios: AxiosInstance, page: Reactive<Page>) => {
  const { appendToAcceptHeaderString } = useBrowserLanguages();
  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    if (page.props.auth.user?.language) {
      setAcceptLanguage(config, page.props.auth.user.language.locale);
    }
    watch(() => page.props.auth.user, (user) => {
      if (user?.language) {
        setAcceptLanguage(config, user?.language?.locale);
      }
    });

    return config;
  })

  const setAcceptLanguage = (config: InternalAxiosRequestConfig, locale: string) => {
    config.headers['Accept-Language'] = appendToAcceptHeaderString(locale);
  }
};
