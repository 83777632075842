<script setup lang="ts">
import Avatar from 'primevue/avatar';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import { ref } from 'vue';
import SideBarMenu from '@/Layouts/Menus/SideBarMenu.vue';
import ApplicationLogo from '@/Pages/Auth/ApplicationLogo.vue';
import { Link } from '@inertiajs/vue3';

const isMobileMenuOpen = ref(false);

</script>

<template>
  <div class="w-full xl:w-80">
    <div class="flex justify-between items-center">
      <Link class="xl:mx-auto" :href="route('ui.dashboard.show', $page.props.activeCustomer.uuid)">
        <ApplicationLogo class="xl:mx-auto mt-1" />
      </Link>
      <div class="xl:hidden">
        <Button
          @click="isMobileMenuOpen = !isMobileMenuOpen"
          text
          :icon="isMobileMenuOpen ? 'pi pi-times' : 'pi pi-bars'"
          severity="secondary"
          aria-label="Bookmark"
        />
      </div>
    </div>

    <div class="hidden xl:block mt-6 xl:mt-10">
      <div class="text-lg font-semibold text-center">
        DMARC Manager
      </div>

      <Divider />
      <div class="px-3">
        <div class="flex items-center gap-5">
          <Avatar class="shrink-0" icon="pi pi-user" size="large" />
          <div class="flex justify-between">
            {{ $page.props.auth.user?.name ?? '' }}
            {{ $page.props.activeCustomer.name }}
          </div>
        </div>
      </div>
      <Divider />
    </div>

    <SideBarMenu
      :is-mobile-menu-open="isMobileMenuOpen"
    />
  </div>

</template>
